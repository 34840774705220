import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Response, Headers, RequestOptions, Request, RequestMethod } from '@angular/http';
import { FormBuilder, FormGroup, FormControl , Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
// import { FileUploader } from 'ng2-file-upload/ng2-file-upload';
// import { Ng2ImgToolsService } from 'ng2-img-tools';
// import { Ng2ImgMaxService } from 'ng2-img-max';
// import { ImageCompressService, ResizeOptions, ImageUtilityService, IImage, SourceImage } from  'ng2-image-compress';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';


import { md5, FileService, MysqlService, LibsService, LocalData,
		CategoryService,
        UserService, EmailService,
        ProductService, CarrierService,
        //  OpenhouseService,   DevelopmentService, NeighborhoodService
         } from '../_services';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';

import * as $ from 'jquery';
// import * as XLSX from 'xlsx';
// const URL = 'http://uploads.urbanlivingtx.com/images/';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  currentUser = {};
  users: any;
  apiUrl = "http://ulapi.urbanlivingtx.com/";
  uploadUrl = this._localDB.uploadUrl;
  // apiUrl = 'http://localhost/images/';
  countUsers = 0;

  selectedFiles = [];
  uploadedFiles: any = [];
  barcodeElementType : 'url' | 'canvas' | 'img' = 'url';
  qrcodeValue : string = 'www.doleconnections.com';

  arrayBuffer:any;
  excelFiles: FileList;
  excelFilesUpload = [];
  file: File;

  emailsAll = [];
  emailTypes = [];
  emails = [];
  emailsOriList = [];

  openhouses = [];
  ohs = [];
  categories = [];
  
  products = [];
  productsOriList = [];
  productLoaded = false;
  productsLogo = [];

  carriers = [];
  carriersOriList = [];
  carrierLoaded = false;
  cDevelopments = 0;  

  neighborhoods = [];
  neighborhoodsOriList = [];
  neighborhoodLoaded = false;
  countNeigh = 0;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
    private fileService: FileService,    
    private userService: UserService,
    private emailService: EmailService,
    private productService: ProductService,
    // private openhouseService: OpenhouseService,
    private carrierService: CarrierService,
    // private neighborhoodService: NeighborhoodService,
    private categoryService: CategoryService,
    private http: Http, 
    private httpClient: HttpClient, 
    private elem: ElementRef,
    private _mysql: MysqlService,
    private _libs: LibsService,
    private _localDB: LocalData,
    // private globals: Globals,
  ) {
    this.emailsAll = this._libs.getLocalStorageItem('allEmails');
    this.openhouses = this._libs.getLocalStorageItem('openhouses');
    this.users = this._libs.getLocalStorageItem('users');
    this.products = this._libs.getLocalStorageItem('products');
    this.carriers = this._libs.getLocalStorageItem('carriers');
    this.neighborhoods = this._libs.getLocalStorageItem('neighborhoods');

    // this.currentUser = sessionStorage.getItem('currentUser') ? JSON.parse(sessionStorage.getItem('currentUser')) : {};
    // $('body').addClass('page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo').removeClass('login');
    // $.getScript('../../../assets/global/scripts/app.min.js');
    // $.getScript('../../../assets/pages/scripts/dashboard.min.js');
    // $.getScript('../../../assets/layout/js/layout.min.js');
    // $.getScript('../../../assets/layout/js/regular-page.js');
  }


  ngOnInit() {
    if (this.users.length == 0){
      this.getUsersList();
    }else{
      this.getCategoriesList()
      // this.getFullInfoUsers();
    }
    // if (this.neighborhoods.length == 0){
    //   this.getNeighborhoodsList();
    // }else{
    //   this.getNeighborhoodsImg();
    // }
    if (this.carriers.length == 0){
      this.getCarriersList();
    }else{
      this.carrierLoaded = true;
    }

    this.emailTypes = this._localDB.emailType;
    this.emailTypes = this.emailTypes.filter(e => e['id'] != 4);
    this.emailTypes = this.emailTypes.filter(e => e['id'] != 6);
    if (this.emailsAll.length == 0){
      this.getEmailsList();
    }else{
      this.listEmailDetails();
    }
    this._libs.unLoading();
   
    // (<HTMLInputElement>document.getElementById("bg-loading")).classList.remove("loading"); 
  }

  ngAfterViewInit() {
    this._libs.unLoading();
  }
  
  ngAfterContentInit(){
  }

  ///USERS
  getUsersList() {
    // Use snapshotChanges().map() to store the key
    this.userService.getUsersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ uid: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(customers => {
      this.users = customers;
      this._libs.setLocalStorageItem('users', this.users);
      // console.log(this.users);
      // this.getFullInfoUsers();
      this.getCategoriesList()
      this.getUserDetails(this.currentUser['uid']);
    });
  }

  getUserDetails(uid){
    for (var u = 0; u < this.users.length; ++u) {
      if (md5(this.users[u]['uid']) == uid){
        // return this.users[u];
        console.log(this.users[u]);
        this.currentUser = {
                              'email' : this.users[u]['email'],
                              'phone' : this.users[u]['phone'],
                              'name' : this.users[u]['name'],
                              'uid' : md5(this.users[u]['uid']),
                           }
        sessionStorage.setItem('currentUser', JSON.stringify(this.currentUser));    
        break;
      }
    }
  }

  // getFullInfoUsers(){
  //   if (this.categories.length == 0){
  //     this.getCategoriesList();
  //   }
  // }

  ///EMAIL
  getEmailsList(){
    this.emailService.getEmailsList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(emails => {
      this.emailsAll = emails.sort(this._libs.dynamicSortByNameSort('datetime', 'asc'));
      this.listEmailDetails();
    });
  }

  listEmailDetails(){
    for (var i = 0; i < this.emailsAll.length; ++i) {
      this.emailsAll[i]['date'] = this._libs.convertTimestamp2Date(this.emailsAll[i]['datetime']);
      this.emailsAll[i]['time'] = this._libs.convertTimestamp2Time(this.emailsAll[i]['datetime']);
      this.emailsAll[i]['typeName'] = this.getEmailType(this.emailsAll[i]['type']);
    }
    // console.log(this.emailsAll);
    this._libs.setLocalStorageItem('allEmails', this.emailsAll);
    var regularList = this.emailsAll.filter(em => em['isDelete'] == false);
    this.emailsOriList = regularList;
    this.emails = regularList.filter(em => em['isSent'] == false);  
    // console.log(this.emails);
    this._libs.unLoading();
  }

  getEmailType(id){
    var tempType = this.emailTypes.filter(ft => ft['id'] == id);
    return tempType[0]['type'];
  }

  readEmail(id){
    var isTempRead = {
      isRead: true,
      emailId: id
    }
    this._libs.setLocalStorageItem('isReadEmail', isTempRead);
    this._libs.go2Page('email');
  }

  searchEmail  (e: any){
    // console.log(e);
    var text = e.toLowerCase();
    if (e.length > 0){
      var temp = [];
      for (var i = 0; i < this.emailsOriList.length; ++i) {
        var tempName = (this.emailsOriList[i]['name']).toLowerCase();
        if (tempName != undefined && tempName != ''){
          if (tempName.indexOf(e) >= 0){
            temp.push(this.emailsOriList[i]);
          }
        }
      }
      this.emails = temp;
    }else{
      this.listEmailDetails();
    }
  }

  ///OPEN HOUSE
  // getOpenHousesList(){
  //   this.openhouseService.getOpenHousesList().snapshotChanges().pipe(
  //     map(changes =>
  //       changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
  //     )
  //   ).subscribe(ophos => {
  //     this.openhouses = ophos.sort(this._libs.dynamicSortByNameSort('timestamp', 'asc'));
  //     this._libs.setLocalStorageItem('openhouses', this.openhouses)
  //     this.filterOpenHouseList();
  //     // this.listEmailDetails();
  //   });
  // }

  // filterOpenHouseList(){
  //   var lastweek = this._libs.getLastWeekTimestamp();
  //   // console.log(lastweek);
  //   this.ohs = this.openhouses.filter(oh => oh['timestamp'] >= lastweek);
  //   for (var i = 0; i < this.ohs.length; ++i) {
  //     var temp = this.users.filter(u => u['uid'] == this.ohs[i]['userId']);
  //     this.ohs[i]['assignTo'] = temp[0]['firstname']+' '+temp[0]['lastname'];
  //     this.ohs[i]['avatarCode'] = temp[0]['avatarCode'];
  //   }
  //   // console.log(this.openhouses);
  // }

  searchMLS(e){
    // console.log(e);
    // var lastweek = this._libs.getLastWeekTimestamp();
    // var tempOHs = this.openhouses.filter(oh => oh['timestamp'] >= lastweek);
    // var text = e.toLowerCase();
    // if (e.length > 0){
    //   var temp = [];
    //   for (var i = 0; i < tempOHs.length; ++i) {
    //     var tempMls = (tempOHs[i]['mls']).toLowerCase();
    //     if (tempMls != undefined && tempMls != ''){
    //       if (tempMls.indexOf(e) >= 0){
    //         temp.push(tempOHs[i]);
    //       }
    //     }
    //   }
    //   this.ohs = temp;
    // }else{
    //   this.filterOpenHouseList();
    // }
  }

  ///FILE
  docUpload(e:any){
    console.log(e);
  }
  
  onFileSelected(e: any): void {
    this.selectedFiles = e.target.files;
    if(e.target.files && e.target.files.length > 0) {
      for (var f = 0; f < e.target.files.length; ++f) {
        let reader = new FileReader();
        let file = e.target.files[f];
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.uploadedFiles.push({'imgCode': reader.result, 'name': file.name, 'type': file.type, 'size': file.size});
        };
      }
    }
  }

  uploadAll(){
    // console.log(this.apiUrl+'post-image.php');
    let fName = 'fileUpload';
    if (this.selectedFiles.length > 0){
      for (var i = 0; i < this.selectedFiles.length; ++i) {
        this.fileService.uploadFile(this.selectedFiles[i], fName);
      }
    }
  }


  sanitizerUrl(value){
    return this._libs.getSantizeUrl(value);
  }

  ///MEDIA
  getMediasUserByCategory(cId, i): void {
    this._mysql.getAllImagesByCategory(cId)
    .subscribe( (response: Response) => {
        this.countUsers++;
        let medias = response.json();
        if (medias.length > 0){
          var avatarCode = medias.filter(img => img['id'] == this.users[i]['avatar']);
          this.users[i]['avatarCode'] = avatarCode[0]['imgCode'];
        }else{
          this.users[i]['avatarCode'] = this._localDB.noavatar;
        }
        // if (this.countUsers == this.users.length){
        //   // console.log(this.users);
        //   // if (this.openhouses.length == 0){
        //   //   this.getOpenHousesList();
        //   // }else{
        //   //   this.filterOpenHouseList();
        //   // }
        // }
    });
  }

  // getMediasProductByCategory(cId): void {
  //   this._mysql.getAllImagesByCategory(cId)
  //   .subscribe( (response: Response) => {
  //       this.productsLogo = response.json();
  //       if (this.products.length == 0){
  //         this.getProductsList();
  //       }else{
  //         this.getProductsLogo(); 
  //       }
  //   });
  // }

  // getImgCode(i, imgId){
  //   this.neighborhoods[i]['imagesCode'] = [];
  //   this._mysql.getImageById(imgId)
  //   .subscribe( (response: Response) => {
  //     let res = response.json();
  //     this.countNeigh++;
  //     this.neighborhoods[i]['imageCode'] = res[0]['imgCode'];
  //     if (this.neighborhoods.length == this.countNeigh){
  //       this.neighborhoodsOriList = this.neighborhoods;
  //       this.neighborhoodLoaded = true;
  //     }
  //   });
  // }

  ///PRODUCTS
  getProductsList(){
    this.productService.getProductsList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(products => {
      this.products = products.sort(this._libs.dynamicSortByName('name'));
      this.productsOriList = this.products;
      this._libs.setLocalStorageItem('products', this.products);
      // console.log(this.products);
      this.productLoaded = true;
      // this.getProductsLogo();
    });
  }

  // getProductsLogo(){
  //   for (var i = 0; i < this.products.length; ++i) {
  //     var temp = this.productsLogo.filter(logo => logo['id'] == this.products[i]['logo']);
  //     this.products[i]['logoCode'] = this.sanitizerUrl(temp[0]['imgCode']);
  //   }
  //   this.productLoaded = true;
  // }

  viewProduct(id){
    this._libs.setLocalStorageItem('isView', id);
    this._libs.go2Page('products');
  }

  searchProduct(e){
    // console.log(e);
    var text = e.toLowerCase();
    if (e.length > 0){
      var temp = [];
      for (var i = 0; i < this.productsOriList.length; ++i) {
        var tempDev = (this.productsOriList[i]['name']).toLowerCase();
        if (tempDev != undefined && tempDev != ''){
          if (tempDev.indexOf(e) >= 0){
            temp.push(this.productsOriList[i]);
          }
        }
      }
      this.products = temp;
    }else{
      this.products = this._libs.getLocalStorageItem('products');
      // this.getProductsLogo();
    }
  }

  //CARRIERS
  getCarriersList(){
    this.carrierService.getCarriersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(carriers => {
      this.carriers = carriers.sort(this._libs.dynamicSortByName('name'));
      sessionStorage.setItem('carriers', JSON.stringify(this.carriers));
      // this.getDevelopmentsLogo();
      this.carrierLoaded = true;
    });
  }

  viewCarrier(id){
    this._libs.setLocalStorageItem('isView', id);
    this._libs.go2Page('carriers');
  }

  // getDevelopmentsLogo(){
  //   for (var i = 0; i < this.carriers.length; ++i) {
  //     // console.log(this.carriers[i]['logo']);
  //     if (this.carriers[i]['logo'] != ''){
  //       this.getDevelopmentLogo(this.carriers[i]['logo'], i)
  //     }
  //   }
  //   this._libs.unLoading();
  // }

  // getDevelopmentLogo(id, i){
  //   this._mysql.getImageById(id)
  //   .subscribe( (response: Response) => {
  //     let res = response.json();
  //     this.carriers[i]['logoCode'] = res[0]['imgCode'];
  //     this.cDevelopments ++;
  //     if (this.cDevelopments == this.carriers.length){
  //       this.carriersOriList = this.carriers;
  //       this.carrierLoaded = true;
  //     }
  //   });
  // }

  searchCarrier(e){
    // console.log(e);
    var text = e.toLowerCase();
    if (e.length > 0){
      var temp = [];
      for (var i = 0; i < this.carriersOriList.length; ++i) {
        var tempMls = (this.carriersOriList[i]['name']).toLowerCase();
        if (tempMls != undefined && tempMls != ''){
          if (tempMls.indexOf(e) >= 0){
            temp.push(this.carriersOriList[i]);
          }
        }
      }
      this.carriers = temp;
    }else{
      this.carriers = this._libs.getLocalStorageItem('devs');
      // this.getDevelopmentsLogo();
    }
  }

  ///NEIGHBORHOOD
  // getNeighborhoodsList(){
  //   this.neighborhoodService.getNeighborhoodsList().snapshotChanges().pipe(
  //     map(changes =>
  //       changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
  //     )
  //   ).subscribe(neighborhoods => {
  //     this.neighborhoods = neighborhoods;
  //     this._libs.setLocalStorageItem('neighborhoods', neighborhoods);
  //     this.getNeighborhoodsImg();
  //     // console.log(this.neighborhoods);
  //   });
  // }

  // getNeighborhoodsImg(){
  //   for (var i = 0; i < this.neighborhoods.length; ++i) {
  //     this.getImgCode(i, this.neighborhoods[i]['images'][0]);
  //   }
  // }

  searchNeighborhood(e){
    var text = e.toLowerCase();
    // if (e.length > 0){
    //   var temp = [];
    //   for (var i = 0; i < this.neighborhoodsOriList.length; ++i) {
    //     var tempMls = (this.neighborhoodsOriList[i]['name']).toLowerCase();
    //     if (tempMls != undefined && tempMls != ''){
    //       if (tempMls.indexOf(e) >= 0){
    //         temp.push(this.neighborhoodsOriList[i]);
    //       }
    //     }
    //   }
    //   this.neighborhoods = temp;
    // }else{
    //   this.neighborhoods = this._libs.getLocalStorageItem('neighborhoods');
    //   this.getNeighborhoodsImg();
    // }
  }

  ///CATEGORY
  getCategoriesList(){
    this.categoryService.getCategoriesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(categories => {
      this.categories = categories;
      var categoryUsers = categories.filter(category => category['type'] == '2');
      for (var i = 0; i < this.users.length; ++i) {
        let categoryId = this._libs.findIdFromArrayByName(categoryUsers, this.users[i]['email']);
        if (categoryId != undefined){
          // this.getMediasUserByCategory(categoryId, i);
        }
      }

      var categoryProducts = categories.filter(category => category['type'] == '1');
      var catgoryProductId = this._libs.findIdFromArrayByName(categoryProducts, 'Products');
      if (this.products.length == 0) this.getProductsList();
      else this.productLoaded = true;

      
      // this.getMediasProductByCategory(catgoryProductId);
    });
  }

  ///QRCODE
  generatedQrCode(e: any){
    // console.log(e.target.value);
    this.qrcodeValue = e.target.value;
  }

  ///FILE EXCEL / XLSX
  inComingFiles(event){
    this.excelFiles = event.target.files;
    this.excelFilesUpload = [];
    // if(this.excelFiles && this.excelFiles.length > 0) {
    //   for (var f = 0; f < this.excelFiles.length; ++f) {
    //     let fileReader = new FileReader();
    //     let file = this.excelFiles[f];
    //     fileReader.onload = (e) => {
    //         this.arrayBuffer = fileReader.result;
    //         var data = new Uint8Array(this.arrayBuffer);
    //         var arr = new Array();
    //         for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
    //         var bstr = arr.join("");
    //         var workbook = XLSX.read(bstr, {type:"binary"});
    //         var first_sheet_name = workbook.SheetNames[0];
    //         var worksheet = workbook.Sheets[first_sheet_name];
    //         console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));
    //     }
    //     fileReader.readAsArrayBuffer(this.file);
    //   }
    // }
  }

  Upload() {
    // console.log(this.selectedFiles);

      // let fileReader = new FileReader();
      // fileReader.onload = (e) => {
      //     this.arrayBuffer = fileReader.result;
      //     var data = new Uint8Array(this.arrayBuffer);
      //     var arr = new Array();
      //     for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      //     var bstr = arr.join("");
      //     var workbook = XLSX.read(bstr, {type:"binary"});
      //     var first_sheet_name = workbook.SheetNames[0];
      //     var worksheet = workbook.Sheets[first_sheet_name];
      //     console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));
      // }
      // fileReader.readAsArrayBuffer(this.file);
  }
}
