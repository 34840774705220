import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { MenuModel, SubmenuModel } from '../_services/menu.model';
 
@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menusRef: AngularFireList<MenuModel> = null;
  submenusRef: AngularFireList<SubmenuModel> = null;
  submenusL2Ref: AngularFireList<SubmenuModel> = null;

  constructor(
    private afData: AngularFireDatabase,
  ) {
    this.menusRef = afData.list('/menus');
    this.submenusRef = afData.list('/submenus');
    this.submenusL2Ref = afData.list('/submenusl2');
  }

  ///MENU
  getMenusList(): AngularFireList<MenuModel> {
    return this.menusRef;
  }

  getMenu(id): AngularFireList<MenuModel> {
    return this.afData.list('/menus/'+id);
  }

  updateMenu(key, values) {
    this.menusRef.set(key, values);
  }

  createMenu(menu: MenuModel): void {
    this.menusRef.push(menu);
  }

  deleteMenu(key: string): void {
    this.menusRef.remove(key).catch(error => this.handleError(error));
  }

  ///SUBMENU
  getSubmenusList(): AngularFireList<SubmenuModel> {
    return this.submenusRef;
  }

  getSubmenu(id): AngularFireList<SubmenuModel> {
    return this.afData.list('/submenus/'+id);
  }

  updateSubmenu(key, values) {
    this.submenusRef.set(key, values);
  }

  createSubmenu(submenu: SubmenuModel): void {
    this.submenusRef.push(submenu);
  }

  deleteSubmenu(key: string): void {
    this.submenusRef.remove(key).catch(error => this.handleError(error));
  }

  ///SUBMENU
  getSubmenusL2List(): AngularFireList<SubmenuModel> {
    return this.submenusL2Ref;
  }

  getSubmenuL2(id): AngularFireList<SubmenuModel> {
    return this.afData.list('/submenusl2/'+id);
  }

  updateSubmenuL2(key, values) {
    this.submenusL2Ref.set(key, values);
  }

  createSubmenuL2(submenu: SubmenuModel): void {
    this.submenusL2Ref.push(submenu);
  }

  deleteSubmenuL2(key: string): void {
    this.submenusL2Ref.remove(key).catch(error => this.handleError(error));
  }

  private handleError(error) {
    console.log(error);
  }
}
