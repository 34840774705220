export class BrochureModel {
  name: string;
  filename: string;
  code: string;

  constructor(){
    this.name = "";
    this.filename = "";
    this.code = "";
  }
}
