import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserModel, UserService, 
        CategoryModel, CategoryService,
        RoleModel, RoleService,
    		LibsService, AuthenticationService, MysqlService, LocalData } from '../_services';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  users = [];
  user: UserModel = new UserModel();
  key = '';
  temporaryPassword = '';
  result = '';
  currentUser = [];
  categories = [];
  roles = [];

  title = 'Add';
  btnText = 'Create';
  isLoaded = false;

  isValidFirstName =  true;
  isValidLastName = true;
  isValidEmail = true;
  isValidPassword = true;
  isValidPhone = true;
  isValidOccupation = true;
  isValidRole = true;
  isDeleted = false;
  isUpload = false;
  
  modalId = '';
  isModal = 'none';

  uploadUrl = this._localData.uploadUrl;
  today: number = Date.now();
  fileUpload = {
                'imgCode': '',
                'name': '',
                'type': '',
                'size': 0,
                'uploadat': this.today
              };
  selectedFile: File;
  tempMedias = [];
  alertMessage = '';

  constructor(
    private authService: AuthenticationService,
  	private userService: UserService,
  	private fb: FormBuilder,
    private _libs: LibsService,
    private _localData: LocalData,
    private _mysql: MysqlService,
    private categoryService: CategoryService,
    private roleService: RoleService,
  ) {
    this.currentUser = this._libs.getLocalStorageItem('currentUser');
    if (this.categories.length == 0){
      this.getCategoriesList();
    }
    // this.roles = this._libs.getLocalStorageItem('roles');
    this.getRolesList();    
  }

  ngOnInit() {
  }

  generatePassword(){
  	return this._libs.generatePassword();
  }

  getUsersList(){
  	this.userService.getUsersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(users => {
      this.users = users;
      for (var i = 0; i < this.users.length; ++i) {
        this.users[i]['roleName'] = this.getRoleName(this.users[i]['roleId'])
      }
      this._libs.unLoading();
      this.isLoaded = true;
      // console.log(this.users);
    });
  }

  getUser(id){
    this.key = id;
    var temp = this.users.filter(u => u['id'] == id);
    return temp[0];
  }

  onSubmitUser() {
    let firstname : string = (<HTMLInputElement>document.getElementById("firstname")).value; 
    let lastname : string = (<HTMLInputElement>document.getElementById("lastname")).value; 
    let email : string = (<HTMLInputElement>document.getElementById("email")).value; 
    let password : string = (<HTMLInputElement>document.getElementById("password")).value; 
    let phone : string = (<HTMLInputElement>document.getElementById("phone")).value; 
    let occupation : string = (<HTMLInputElement>document.getElementById("occupation")).value; 
  	
  	if(firstname == '' || firstname == null){
  		this.isValidFirstName = false
  		return;
  	}else{
  		this.isValidFirstName = true;
  	}
  	if(lastname == '' || lastname == null){
  		this.isValidLastName = false
  		return;
  	}else{
  		this.isValidLastName = true;
  	}
  	if(email == '' || email == null){
  		this.isValidEmail = false
  		return;
  	}else{
  		this.isValidEmail = true;
  	}
    if (this.key == ''){
    	if(password == '' || password == null){
    		this.isValidPassword = false
    		return;
    	}else{
    		this.isValidPassword = true;
    	}
    }else{

    }
  	if(phone == '' || phone == null){
  		this.isValidPhone = false
  		return;
  	}else{
  		this.isValidPhone = true;
  	}
  	if(occupation == '' || occupation == null){
  		this.isValidOccupation = false
  		return;
  	}else{
  		this.isValidOccupation = true;
  	}
    if(this.user['roleId'] == '' || this.user['roleId'] == null){
      this.isValidRole = false
      return;
    }else{
      this.isValidRole = true;
    }

    // console.log(this.user);
    if (this.user['avatar'] == undefined || this.user['avatar'] == ''){
      this.user['avatar'] = 'noavatar.jpg';
    }
    // let tempUser = this.user;
    // tempUser['password'] = this.temporaryPassword;
    this.user['password'] = this.temporaryPassword; 
    this.user = this._libs.convert2UserModel(this.user);

    if (this.key){      
      this.update(this.user);
    }else{
      if (this.user['avatar'] != 'noavatar.jpg'){
        console.log(this.selectedFile);
        let categoryId = this._libs.findIdFromArrayByName(this.categories, this.user['email']);
        this._mysql.postImage(this.selectedFile, this.user['avatar'], categoryId)
        .subscribe(result => {
          this.result = "Images Added.";
          this.createFirebaseUser(this.user);
        });
      }else{
        this.createFirebaseUser(this.user);
      }
    }
  }

  update(f){
    if (this.isUpload && this.user['avatar'] != 'noavatar.jpg'){
      let categoryId = this._libs.findIdFromArrayByName(this.categories, this.user['email']);
      this._mysql.deleteAllImagesByCategory(categoryId)
      .subscribe(result => {
        this.result = "Images deleted.";
        this._mysql.postImage(this.selectedFile, this.user['avatar'], categoryId)
        .subscribe(result => {
          this.result = "Images Added.";
          this.userService.updateUser(this.key, f);
          this.closeModal();
          this.reset();
        });
      });
      

      //   let categoryId = this._libs.findIdFromArrayByName(this.categories, this.user['email']);
      //   this._mysql.updateImage(this.selectedFile, this.user['avatar'], categoryId)
      //   .subscribe(result => {
      //     this.result = "Images Added.";
      //     this.userService.updateUser(this.key, f);
      //     this.closeModal();
      //     this.reset();
      //   });
    }else{
      let categoryId = this._libs.findIdFromArrayByName(this.categories, this.user['email']);
      this._mysql.updateImage(this.selectedFile, this.user['avatar'], categoryId)
      .subscribe(result => {
        this.result = "Images Added.";
        this.userService.updateUser(this.key, f);
        this.closeModal();
        this.reset();
      });
    }
  }

  setUser(key ,f) {
    this.userService.setUser(key, f);
    let sEmail = {
          'email': f['email'],
          'password': this.temporaryPassword,
          'name': f['firstname'] +' '+f['lastname'],
          'uid': key
      }
    let category = {name: f['email'], type: '2'}
    this.reset();
    this.categoryService.createCategory(category);
    this.emailNewAccount(sEmail);
  }

  emailNewAccount(params){
  	this._mysql.emailNewAccount(params).subscribe(res => {
      let result = res;
      if (res['data']['result'] == 'OK'){
        this.result = res['data']['message'];
        this.getUsersList();
        setTimeout(() => {
          this.result = '';
        }, 3000);
        // this._libs.go2Page('users');
      }
    });
  }

  reset(){
    this.btnText = 'Create';
  	this.title = 'Add';
    // this.form.reset();
    this.user = new UserModel();
    this.key = '';
    this.closeModal();
  }

  userEdit(id, modalId){
    this.btnText = 'Update';
    this.title = 'Edit';
    this.key = id;
    this.user = this.getUser(id);
    this.temporaryPassword = this.user['password'];
    let categoryId = this._libs.findIdFromArrayByName(this.categories, this.user['email']);
    this._mysql.getAllImagesByCategory(categoryId)
    .subscribe( (response: Response) => {
      this.tempMedias = response.json();
      console.log(this.tempMedias);
      this.openModal(modalId);
    });
  }

  userDelete(id,modalId){
    this.user = this.getUser(id);
    this.openModal(modalId);
  }

  confirmYes(){
    // console.log(this.category['id']);
    this.deleteUserCategory();
  }

  newUser(modalId){
  	this.user = new UserModel();
  	this.key = '';
    this.title = 'Add';
    this.btnText = 'Create';
  	this.openModal(modalId);
  }

  openModal(modalId){
  	if (this.key == ''){
  		this.temporaryPassword = this.generatePassword();
  	}
    // console.log(this.temporaryPassword);
    this.alertMessage = '';
    this.modalId = modalId;
    this.isModal = this._libs.openModal(modalId);
  }

  closeModal(){
    this.modalId = '';
    this.isUpload = false;
    this.user = new UserModel();
    this.isModal = this._libs.closeModal();
  }

  activeUser(id){
    var isChecked = $('#'+id).prop("checked");
    console.log(isChecked);
    var temp = this._libs.convert2UserModel(this.getUser(id));
    temp['active'] = isChecked;
    // console.log(temp);
    this.userService.updateUser(id, temp);
    this.getUsersList();
  }

  /// UPLOAD AVATAR
  onUpload(event: any){
    this.isUpload = true;
    // console.log(this.isUpload);
    if(event.target.files && event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];
      let tempType = this.selectedFile['type'];
      let tempExt = tempType.split('/');
      this.user['avatar'] = this.user['firstname']+this.user['lastname']+'.'+tempExt[tempExt.length - 1];
      let reader = new FileReader();
      // let file = event.target.files[0];
      reader.readAsDataURL(this.selectedFile);
      reader.onload = () => {
        let tempName = (reader.result).toString();
        // console.log(this.user);
        this.fileUpload = {'imgCode': tempName, 'name': this.user['avatar'], 'type': tempType, 'size': this.selectedFile['size'], 'uploadat': this.today};
        // uploadedFiles = {'imgCode': reader.result, 'name': file.name, 'type': file.type, 'size': file.size};
        reader = new FileReader();
      //   this.checkExistFile(uploadedFiles);
      };
      this.isLoaded = true;
    }
  }

  ///FIREBASE ACCOUNT
  createFirebaseUser(f){
    this.authService.doRegister(f)
    .then(newU => {
      // console.log(newU);
      f['provider'] = newU.user.providerData[0].providerId;
      this.setUser(newU.user.uid, f);
    }, err => {
      // console.log(err);
      this.alertMessage = err.message;
    })
  }
  
  deleteUserFirebase(f){
    this.authService.doDeleteUser(f.email, f.password)
    .then(user => {
      this.isDeleted = true;
      // console.log(user);
      this.result = 'User is deleted';
      this.currentUser = this.getUser(this._libs.convert2OriginalId(this.currentUser['id']));
      // this.authService.doEmailLogin(this.currentUser['email'], this.currentUser['password']);
    }, err => {
      // console.log(err);
      this.alertMessage = err.message;
    })

    // .then(user => {
    //   console.log(user);
    // }, err => {
    //   console.log(err);
    //   this.alertMessage = err.message;
    // });
  }

  //ROLE
  getRolesList(){
    this.roleService.getRolesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(roles => {
      this.roles = roles;
      // console.log(this.roles);
      this.getUsersList();
    });
  }

  getRole(id){
    return (this.roles.filter(role => role['id'] == id))[0];
  }

  getRoleName(id){
    let tempRole = this.getRole(id);
    return tempRole['name'];
  }

  roleSelected(e: any){
    this.user['roleId'] = e;
  }
  
  ///CATEGORY
  getCategoriesList(){
    this.categoryService.getCategoriesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(cates => {
      let categories = cates.filter(category => category['type'] == '2');
      this.categories = categories;
      // console.log(this.categories);
    });
  }

  deleteUserCategory(){
    let categoryId = this._libs.findIdFromArrayByName(this.categories, this.user['email']);
    if (categoryId != undefined){
      this.categoryService.deleteCategory(categoryId);
      this.userService.deleteUser(this.key);
      this.getMediasByCategory(categoryId);
      this.closeModal();
    }
  }

  ///MEDIA
  getMediasByCategory(cId): void {
    this._mysql.getAllImagesByCategory(cId)
    .subscribe( (response: Response) => {
        let medias = response.json();
        this.deleteMedia(medias);
        this.deleteUserFirebase(this.user);
        // this.user = new UserModel();
        this.closeModal();
    });
  }

  deleteMedia(medias){
    for (var d = 0; d < medias.length; ++d) {
      this._mysql.deleteImage(medias[d]['id'])
      .subscribe(result => {
        this.result = "Images deleted.";
      });
    }
  }

}
