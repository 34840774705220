import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { CategoryModel } from './category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  categoryRef: AngularFireList<CategoryModel> = null;
  
  constructor(
  	private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
  ) {
  	this.categoryRef = afData.list('/categories');
  }

  getCategoriesList(): AngularFireList<CategoryModel> {
    return this.categoryRef;
  }

  getCategory(id): AngularFireList<CategoryModel> {
    return this.afData.list('/packages/'+id);
  }

  updateCategory(key, cate) {
    this.categoryRef.set(key, cate);
  }

  createCategory(cate: CategoryModel): void {
    this.categoryRef.push(cate);
  }

  deleteCategory(key: string): void {
    this.categoryRef.remove(key).catch(error => this.handleError(error));
  }

  deleteAll(): void {
    this.categoryRef.remove().catch(error => this.handleError(error));
  }
  
  private handleError(error) {
    console.log(error);
  }

}
