import { Injectable } from '@angular/core';

@Injectable()
export class LocalData {
  urls = 'https://www.dothang.net.com';
  matterportUrl = 'https://my.matterport.com/show/?m=';
  noavatar = './assets/layout/img/avatar.jpg'
  uploadUrl = 'http://upload.doleconnections.com/';

  houston = {lat: 29.757096, lng: -95.363805};
  infor = 
            {
              'imgs':      [
                            {"id":731,"img":"731"},
                            {"id":732,"img":"732"},
                            {"id":736,"img":"736"}
                            ],
              'address1':  '12226 Claresholm Dr',
              'address2':  'Tomball, TX 77377',
              'phone1':    '708-248-0129',
              'phone2':    '708-890-4605',
              'fax':       '888-123-4567',
              'name':      'DoLe Connections App',
              'email':     'doleconnections@gmail.com',
              'www':       'www.doleconnections.com',
              'logo':      'logo.png',
              'logoW':     'logo-w.png',
              'logo82':    'logo-82.png',
              'slogoFile': 'logo-62.png',
              'facebook':  'https://www.facebook.com/doleconnections',
              'instagram': 'https://www.instagram.com/doleconnections/?hl=en',
              'twitter':   'https://twitter.com/doleconnections',
              'skype':     'doleconnections',
              'days':      'Open 7 days a week',
              'hours':     'from 9:00 am to 7:00 pm'
            };
  
  emailPreferences = [
    {id: 1, name: 'Immediately'},
    {id: 2, name: 'Daily'},
    {id: 3, name: 'Never'},
  ];

  categoryType = [
      {id: 1, 'name': 'general'}, // Other, Products
      {id: 2, 'name': 'profile'},
      {id: 3, 'name': 'company'},
      {id: 4, 'name': 'carriers'},
  ];

  emailType = [
      {id: 1, type: 'contact', name: 'Contact'},
      {id: 2, type: 'lending', name: 'Lending'},
      {id: 3, type: 'tour', name: 'Tour'},
      {id: 4, type: 'register', name: 'Register'},
      {id: 5, type: 'sellyourhome', name: 'Sell Your Home'},
      {id: 6, type: 'sendnew', name: 'Send New'},
  ]

  videoType = [
                { id: '1', key: 'video', name: 'Video'},
                { id: '2', key: 'tour', name: 'Virtual Tour'}
              ]
           
  states = [
            { id: '1', name: 'Alabama', code: 'AL'},
            { id: '2', name: 'Alaska', code: 'AK'},
            { id: '3', name: 'Arizona', code: 'AZ'},
            { id: '4', name: 'Arkansas', code: 'AR'},
            { id: '5', name: 'California', code: 'CA'},
            { id: '6', name: 'Colorado', code: 'CO'},
            { id: '7', name: 'Connecticut', code: 'CT'},
            { id: '8', name: 'Delaware', code: 'DE'},
            { id: '9', name: 'Florida', code: 'FL'},
            { id: '10', name: 'Georgia', code: 'GA'},
            { id: '11', name: 'Hawaii', code: 'HI'},
            { id: '12', name: 'Idaho', code: 'ID'},
            { id: '13', name: 'Illinois', code: 'IL'},
            { id: '14', name: 'Indiana', code: 'IN'},
            { id: '15', name: 'Iowa', code: 'IA'},
            { id: '16', name: 'Kansas', code: 'KS'},
            { id: '17', name: 'Kentucky', code: 'KY'},
            { id: '18', name: 'Louisiana', code: 'LA'},
            { id: '19', name: 'Maine', code: 'ME'},
            { id: '20', name: 'Maryland', code: 'MD'},
            { id: '21', name: 'Massachusetts', code: 'MA'},
            { id: '22', name: 'Michigan', code: 'MI'},
            { id: '23', name: 'Minnesota', code: 'MN'},
            { id: '24', name: 'Mississippi', code: 'MS'},
            { id: '25', name: 'Missouri', code: 'MO'},
            { id: '26', name: 'Montana', code: 'MT'},
            { id: '27', name: 'Nebraska', code: 'NE'},
            { id: '28', name: 'Nevada', code: 'NV'},
            { id: '29', name: 'New Hampshire', code: 'NH'},
            { id: '30', name: 'New Jersey', code: 'NJ'},
            { id: '31', name: 'New Mexico', code: 'NM'},
            { id: '32', name: 'New York', code: 'NY'},
            { id: '33', name: 'North Carolina', code: 'NC'},
            { id: '34', name: 'North Dakota', code: 'ND'},
            { id: '35', name: 'Ohio', code: 'OH'},
            { id: '36', name: 'Oklahoma', code: 'OK'},
            { id: '37', name: 'Oregon', code: 'OR'},
            { id: '38', name: 'Pennsylvania', code: 'PA'},
            { id: '39', name: 'Rhode Island', code: 'RI'},
            { id: '40', name: 'South Carolina', code: 'SC'},
            { id: '41', name: 'South Dakota', code: 'SD'},
            { id: '42', name: 'Tennessee', code: 'TN'},
            { id: '43', name: 'Texas', code: 'TX'},
            { id: '44', name: 'Utah', code: 'UT'},
            { id: '45', name: 'Vermont', code: 'VT'},
            { id: '46', name: 'Virginia', code: 'VA'},
            { id: '47', name: 'Washington', code: 'WA'},
            { id: '48', name: 'West Virginia', code: 'WV'},
            { id: '49', name: 'Wisconsin', code: 'WI'},
            { id: '50', name: 'Wyoming', code: 'WY'}
           ]

}