import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Http, Response, Headers } from '@angular/http';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as $ from 'jquery';

import { LocalData, AuthenticationService, UserModel, LibsService, MysqlService, 
          CategoryService, MenuService, RoleService,
          CompanyModel, CompanyService,
        } from '../../_services';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css'],
})
export class AppLayoutComponent implements OnInit {
	currentUser: UserModel = new UserModel();
  today = new Date();
  company: CompanyModel = new CompanyModel();
  avatar: SafeResourceUrl;
  medias = [];
  ulMedias = [];
  uploadUrl = this._localDB.uploadUrl;

  selectedCatgory = '';
  selectedUlCatgory = '';
  categories = [];
  menus = [];
  allSubmenus = [];
  submenus = [];
  submenusL2 = [];

  roles = [];
  permission = [];
  userPermission = [];

  isLogged = false;

  // urbanliving = [];
  key = '';

  logoImg: SafeResourceUrl;
  logoBWImg: SafeResourceUrl;
  logoMobImg: SafeResourceUrl;
  imgCodes = [];

  constructor(
    private _localDB: LocalData,
    private _mysql: MysqlService,
    private _libs: LibsService,
    private authService: AuthenticationService,
    private categoryService: CategoryService,
    private companyService: CompanyService,
    private menuService: MenuService,
    private roleService: RoleService,
  ) {
    $.getScript('../../../assets/global/scripts/app.min.js');
    $.getScript('../../../assets/pages/scripts/dashboard.min.js');
    $.getScript('../../../assets/layout/js/layout.min.js');
    this.currentUser = this._libs.getLocalStorageItem('currentUser');
    this.categories = this._libs.getLocalStorageItem('categories'); 
    this.menus = this._libs.getLocalStorageItem('menus');
    this.allSubmenus = this._libs.getLocalStorageItem('allSubmenus');
    this.roles = this._libs.getLocalStorageItem('roles');
    
    this.company = this._libs.getLocalStorageItem('company');
    // this.company = this._localDB.infor;

    // console.log(this.company);
    
    // console.log(this.currentUser['id']);
    if (this.currentUser['id'] == undefined || this.currentUser['id'] == ''){
      this.isLogged = false;
      this._libs.go2Page('login');
    }else{
      $.getScript('../../../assets/layout/js/regular-page.js');
      this.isLogged = true;
      this._libs.loading();
      if (this.roles.length == 0){
        this.getRolesList();
      }else{
        this.step2();
      }
    }
  }

  ngOnInit() {
  
  }

  step2(){
    if (this.menus.length == 0){
      this.getMenusList();
    }else{
      this.step3();
    }
    
  }

  step3(){
    this.getuserMenu();  
    if (this.allSubmenus.length == 0){
      this.getSubmenusList();
    }else{
      this.submenus = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == false);
      this.submenusL2 = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == true);
    }
    if (this.categories.length == 0){
      this.getCategoriesList();
    }else{
      this.getUserCategory();
    }
  }
  
  sanitizerUrl(value){
    return this._libs.getSantizeUrl(value);
  }

  getuserMenu(){
    if (this.currentUser['roleId'] != ''){
      var temp = this.getRole(this.currentUser['roleId']);
      this.permission = temp['permission'];
      if (this.menus.length > 0 && this.permission.length > 0){
        // console.log(this.permission);
        // console.log(this.menus);
        // console.log(this.roles);
        this.userPermission = this._libs.similarArr(this.menus, this.permission);
      }
    }
  }

  getUserCategory(){
    if (this.currentUser['email'] != ''){
      this.selectedCatgory = this._libs.findIdFromArrayByName(this.categories, this.currentUser['email']);
      // this.getMediasByCategory(this.selectedCatgory);
    }
  }

  logout() {
    // sessionStorage.removeItem('currentUser');
    sessionStorage.clear();
    this.authService.doSignOut();
    this._libs.go2Page('login');
  }

  ///CATEGORY
  getCategoriesList(){
    this.categoryService.getCategoriesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ 'id': c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(categories => {
      this.categories = categories;
      // this._libs.setLocalStorageItem('categories', categories);
      // console.log(this.categories);
      this.getUserCategory();
    });
  }

  ///MEDIA
  getMedia(id){
    // console.log(this.medias);
    return this.medias.filter(media => media['id'] == id);
  }
  
  getImgCode(id){
    let avatarImg = this.getMedia(id);
    if (avatarImg != undefined){
      return this.sanitizerUrl(avatarImg[0]['imgCode']);
    }
  }

  // getMediasByCategory(cId): void {
  //   this._mysql.getAllImagesByCategory(cId)
  //   .subscribe( (response: Response) => {
  //       this.medias = response.json();
  //       if (this.currentUser['avatar'] == "noavatar" || this.currentUser['avatar'] == "noavatar.jpg"){
  //         this.avatar = this._localDB.noavatar;
  //       }else{
  //         if (this.medias.length > 0){
  //           this.avatar = this.getImgCode(this.currentUser['avatar']);
  //           // this._libs.go2Page('forms');
  //         }
  //       }
  //       this._libs.unLoading();
  //   });
  // }


  getMediaItem(id, item): void{
    // console.log(this.company);
    let tempImg = [];
    if (this.ulMedias.length > 0){
      tempImg = this.ulMedias.filter(media => media['id'] == id);
      if (item == 'logoImg'){
        // this.company['logoImg'] = this.sanitizerUrl(tempImg[0]['imgCode']);
      }else if (item == 'logoBWImg'){
        // this.company['logoBWImg'] = this.sanitizerUrl(tempImg[0]['imgCode']);
      }else if (item == 'logoMobImg'){
        // this.company['logoMobImg'] = this.sanitizerUrl(tempImg[0]['imgCode']);
      }else{
        this.imgCodes.push({ 'id': id, 'imgCode': this.sanitizerUrl(tempImg[0]['imgCode']) });
      }
    }
  }  

  ///MENU
  getMenusList(){
    this.menuService.getMenusList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(menus => {
      this.menus = menus;
      this.menus = this.menus.sort(this._libs.dynamicSortByName('order'));
      this._libs.setLocalStorageItem('menus', menus);
      // console.log(this.menus);
      this.step3();
    });
  }

  getSubmenusList(){
    this.menuService.getSubmenusList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(allSubmenus => {
      this.allSubmenus = allSubmenus;
      this.submenus = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == false);
      this.submenusL2 = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == true);
      // this._libs.setLocalStorageItem('allSubmenus', this.allSubmenus);
      // console.log(this.submenus);
    });
  }

  getSubMenusById(id){
    // console.log(id);
    var subMenu = this.submenus.filter(submenu => submenu['menuId'] == id);
    return subMenu.sort(this._libs.dynamicSortByName('order'));
  }

  getSubMenusL2ById(id){
    // console.log(id);
    return this.submenusL2.filter(submenu => submenu['menuId'] == id);
  }

  checkIsSubmenuL2(id){
    let items =  this.submenus.filter(submenu => submenu['menuId'] == id);
    console.log(items);
    if (items.length > 0){
      return true;
    }else{
      return false;
    }
  }

  ///ROLE
  getRolesList(){
    this.roleService.getRolesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(roles => {
      this.roles = roles;
      // this._libs.setLocalStorageItem('roles', roles);
      this._libs.unLoading();
      this.step2();
      // console.log(this.menus);
    });
  }

  getRole(id){
    return (this.roles.filter(role => role['id'] == id))[0];
  }

}
