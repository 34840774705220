import { Component, OnInit, ElementRef, ViewChild, Pipe, PipeTransform, Injectable} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
// import { Observable } from 'rxjs';

import * as $ from 'jquery';
declare var google: any;

import { UserModel } from './user.model';
import { RoleModel } from './role.model';
import { ProductModel } from './product.model';
import { CompanyModel } from './company.model';
import { PriceRangeModel } from './pricerange.model';
import { CarrierModel } from './carrier.model';
import { LocalData } from './localdata';
import { MenuModel, SubmenuModel } from './menu.model';


@Injectable({
  providedIn: 'root'
})
export class LibsService {

  constructor(
    private router: Router,
    private sanitized: DomSanitizer,
    private _localDB: LocalData,
  ) { }

  errorNumber = 'Number only';
  errorEmail = 'Enter Email Address';


  loading(){
    // $('.bg-loading').addClass('loading');
  }
  unLoading(){
    $('.bg-loading').removeClass('loading');
  }

  getSantizeHTML(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

  getSantizeUrl(url : string) {
    return this.sanitized.bypassSecurityTrustUrl(url);
  }

  getLocalStorageItem(key){
    // return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : [];
    return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : [];
  }

  setLocalStorageItem(key, value){
    // localStorage.setItem(key, JSON.stringify(value));
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  removeLocalStorageItem(item){
    // localStorage.removeItem(item);
    sessionStorage.removeItem(item);
  }

  dynamicSortByName(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }

  dynamicSortByNameSort(property, sort) {
    // sort : des/asc
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result
        if (sort == 'asc'){
          result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
        }else{
          result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        }
        return result * sortOrder;
    }
  }

  dynamicSortMultiple() {
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        while(result === 0 && i < numberOfProperties) {
            result = this.dynamicSortByName(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
  }

  findIdFromArrayByName(arr, name){
    name = this.stringLowerCaseNoSpaces(name);
    for (var c = 0; c < arr.length; ++c) {
      if ( this.stringLowerCaseNoSpaces(arr[c]['name']) == name){
        return arr[c]['id'];
      }
    }
  }

  similarArr(arr1, arr2){
    let temp = [];
    // arr1 = [{id:a, icon: abc}, {id: b, icon: abcd}, {id: d, icon: abcde}];
    // arr2 = [a, b, c, d, e];
    for (var i = 0; i < arr1.length; ++i) {
      if (arr2.indexOf(arr1[i]['id']) >= 0){
        temp.push(arr1[i]);
      }
    }
    return temp;
  }

  go2Page(page){
    this.router.navigate(['/'+page]);
  }

  go2PageWithParam(page, param){
    this.router.navigate(['/'+page+'/'+param]);
  }

  generatePassword(){
    return Math.random().toString(36).slice(-8);
  }

  addRemoveItem4Array(id, arry, isNum){
    if (isNum){
      id = parseInt(id);
    }
    // let tempArr = [];
    let index = arry.indexOf(id);
    if (index > -1) {
      arry.splice(index, 1);
    }else{
      arry.push(id);
    }
    return arry;
  }

  formatPhoneNumber(num) {
    var cleaned = ('' + num).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3]
    }
    return null
  }

  /// BEGIN CHECK
  isNumber(n){
    // console.log(this.removeSpecialCharater(n));
    n = this.removeSpecialCharater(n);
    var num = Number(n);
    if (num == n) return true;
    else return false
  }

  isZipCode(z) {
    return (/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(z);
  }

  isEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isFloat(n){
    // console.log(this.removeSpecialCharater(n));
    n = n.replace(/_/g,'');
    n = n.replace(/[&\/\\#,+()$~%'":*?<>{}-]/g,'');
    n = this.removeSpecialCharater(n);
    var num = Number(n);
    if (num == n) return true;
    else return false
  }
  
  numberWithCommas(x) {
    return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }

  numberWithCommas$(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
    return '$'+x;
  }

  convertShortPrice(price){
    var newprice = '';
    if (price < 1000) newprice = '$'+price;
    else if (price >= 1000 && price < 1000000) newprice = '$' + (price / 1000).toFixed(0) + "K";
    else if (price >= 1000000) newprice = '$' + (price / 1000000).toFixed(0) + "M";
    return newprice
    //     ? '$' + (price / 1000).toFixed(0) + "K"
    //     : '$' + (price / 1000000).toFixed(0) + "M";
  }

  /// END CHECK

  /// BEGIN STRING
  removeSpecialCharater(e){
    e = e.replace(/_/g,'');
    return e.replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g,'');
  }

  stringLowerCaseNoSpaces(str){
    str = str.toLowerCase();
    str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'');
    str = str.replace(/ +/g,'');
    str = str.replace(/ /g,'');
    str = str.replace(/\s\s+/g, '');
    return str; //.replace(/  +/g, '');
  }

  /// END STRING

  /// BEGIN CONVERT

  convert2PriceModel(ori){
    let newP = new PriceRangeModel;
    if (ori['min'] > 0 ){      newP['min'] = ori['min']; }else{ newP['min'] = 0 };
    if (ori['max'] > 0 ){      newP['max'] = ori['max']; }else{ newP['max'] = 0 };
    if (ori['order'] > 0 ){      newP['order'] = ori['order']; }else{ newP['order'] = 0 };
    if (ori['name'] != '' && ori['name'] != undefined){      newP['name'] = ori['name']; }else{ newP['name'] = '' };
    if (ori['shortName'] != '' && ori['shortName'] != undefined){      newP['shortName'] = ori['shortName']; }else{ newP['shortName'] = '' };
    return newP;
  }

  convert2CarrierModel(carrier){
    let TempCarrier =  new CarrierModel();
    if (carrier['address']       != '' && carrier['address']       != undefined){ TempCarrier['address']       = carrier['address']; }else{ TempCarrier['address'] = ''}
    if (carrier['city']          != '' && carrier['city']          != undefined){ TempCarrier['city'] = carrier['city']; }else{ TempCarrier['city'] = ''}
    if (carrier['description']   != '' && carrier['description']   != undefined){ TempCarrier['description']   = carrier['description']; }else{ TempCarrier['description'] = ''}
    if (carrier['email']         != '' && carrier['email']         != undefined){ TempCarrier['email']         = carrier['email']; }else{ TempCarrier['email'] = ''}
    if (carrier['logo']          != '' && carrier['logo']          != undefined){ TempCarrier['logo'] = carrier['logo']; }else{ TempCarrier['logo'] = ''}
    if (carrier['name']          != '' && carrier['name']          != undefined){ TempCarrier['name'] = carrier['name']; }else{ TempCarrier['name'] = ''}
    if (carrier['phone']         != '' && carrier['phone']         != undefined){ TempCarrier['phone']         = carrier['phone']; }else{ TempCarrier['phone'] = ''}
    if (carrier['state']         != '' && carrier['state']         != undefined){ TempCarrier['state']         = carrier['state']; }else{ TempCarrier['state'] = ''}
    if (carrier['zipcode']       != '' && carrier['zipcode']       != undefined){ TempCarrier['zipcode']       = carrier['zipcode']; }else{ TempCarrier['zipcode'] = ''}
    if (carrier['active']        != '' && carrier['active']        != undefined){ TempCarrier['active']        = carrier['active']; }else{ TempCarrier['active'] = true}
    if (carrier['features'].length > 0                                          ){ TempCarrier['features']        = carrier['features']; }else{ TempCarrier['features'] = []}
    return TempCarrier
  }

  convert2ProductModel(product){
    let tempProduct =  new ProductModel();
    if (product['name']       != '' && product['name']       != undefined){ tempProduct['name']       = product['name']; }else{ tempProduct['name'] = ''}
    if (product['sku']          != '' && product['sku']          != undefined){ tempProduct['sku']          = product['sku']; }else{ tempProduct['sku'] = ''}
    if (product['price']         != '' && product['price']         != undefined){ tempProduct['price']         = product['price']; }else{ tempProduct['price'] = ''}
    if (product['salePrice']         != '' && product['salePrice']         != undefined){ tempProduct['salePrice']         = product['salePrice']; }else{ tempProduct['salePrice'] = ''}
    if (product['type']         != '' && product['type']         != undefined){ tempProduct['type']         = product['type']; }else{ tempProduct['type'] = ''}
    if (product['quantity']         != '' && product['quantity']         != undefined){ tempProduct['quantity']         = product['quantity']; }else{ tempProduct['quantity'] = ''}
    if (product['description']   != '' && product['description']   != undefined){ tempProduct['description']   = product['description']; }else{ tempProduct['description'] = ''}
    if (product['overview']          != '' && product['overview']          != undefined){ tempProduct['overview']          = product['overview']; }else{ tempProduct['overview'] = ''}
    if (product['specifications']        != '' && product['specifications']        != undefined){ tempProduct['specifications']        = product['specifications']; }else{ tempProduct['specifications'] = ''}
    if (product['categoryId']    != '' && product['categoryId']    != undefined){ tempProduct['categoryId']    = product['categoryId']; }else{ tempProduct['categoryId'] = ''}
    if (product['features'].length > 0                                          ){ tempProduct['features']        = product['features']; }else{ tempProduct['features'] = []}
    if (product['image']          != '' && product['image']          != undefined){ tempProduct['image']          = product['image']; }else{ tempProduct['image'] = ''}
    if (product['active']        != '' && product['active']        != undefined){ tempProduct['active']        = product['active']; }else{ tempProduct['active'] = false}
    if (product['isSale']        != '' && product['isSale']        != undefined){ tempProduct['isSale']        = product['isSale']; }else{ tempProduct['isSale'] = false}
    if (product['isFeature']        != '' && product['isFeature']        != undefined){ tempProduct['isFeature']        = product['isFeature']; }else{ tempProduct['isFeature'] = false}
    return tempProduct
  }

  convertSize(fileSize){
    return fileSize < 1024000
        ? (fileSize / 1024).toFixed(2) + " KB"
        : (fileSize / 1024000).toFixed(2) + " MB";
  }

  convert2MenuModel(menu){
    let tempMenu =  new MenuModel();
    if (menu['name'] != ''){ tempMenu['name'] = menu['name']; }
    if (menu['icon'] != ''){ tempMenu['icon'] = menu['icon']; }
    if (menu['link'] != ''){ tempMenu['link'] = menu['link']; }
    if (menu['order'] != ''){ tempMenu['order'] = menu['order']; }
    if (menu['submenu'] != ''){ tempMenu['submenu'] = menu['submenu']; }
    return tempMenu
  }

  convert2RoleModel(role){
    let tempRole =  new RoleModel();
    if (role['name'] != ''){ tempRole['name'] = role['name']; }
    if (role['permission'] != ''){ tempRole['permission'] = role['permission']; }
    return tempRole
  }

  convert2SubMenuModel(submenu){
    let tempSubmenu =  new SubmenuModel();
    if (submenu['menuId'] != ''){ tempSubmenu['menuId'] = submenu['menuId']; }
    if (submenu['name'] != ''){ tempSubmenu['name'] = submenu['name']; }
    if (submenu['icon'] != ''){ tempSubmenu['icon'] = submenu['icon']; }
    if (submenu['link'] != ''){ tempSubmenu['link'] = submenu['link']; }
    if (submenu['submenuL2'] != ''){ tempSubmenu['submenuL2'] = submenu['submenuL2']; }
    if (submenu['isSubmenuL2'] != ''){ tempSubmenu['isSubmenuL2'] = submenu['isSubmenuL2']; }
    if (submenu['order'] != ''){ tempSubmenu['order'] = submenu['order']; }
    return tempSubmenu
  }

  convert2UserModel(user){
    let currentUser = new UserModel();
    if (user['email'] != ''){ currentUser['email'] = user['email']; }
    if (user['firstname'] != ''){ currentUser['firstname'] = user['firstname']; }
    if (user['lastname'] != ''){ currentUser['lastname'] = user['lastname']; }
    if (user['provider'] != ''){ currentUser['provider'] = user['provider']; }
    if (user['phone'] != ''){ currentUser['phone'] = user['phone']; }
    if (user['ext'] != undefined && user['ext'] != ''){ currentUser['ext'] = user['ext']; }
    if (user['cellphone'] != undefined && user['cellphone'] != ''){ currentUser['cellphone'] = user['cellphone']; }
    if (user['occupation'] != ''){ currentUser['occupation'] = user['occupation']; }
    if (user['about'] != undefined && user['about'] != ''){ currentUser['about'] = user['about']; }
    if (user['avatar'] != ''){ currentUser['avatar'] = user['avatar']; }
    if (user['password'] != ''){ currentUser['password'] = user['password']; }
    if (user['active'] != undefined){ currentUser['active'] = user['active']; }
    currentUser['emailPreference'] = user['emailPreference'];
    currentUser['roleId'] = user['roleId'];
    return currentUser;
  }

  convert2CompanyModel(urban){
    let temp =  new CompanyModel();
    if (urban['name'] != ''){ temp['name'] = urban['name']; }
    if (urban['address1'] != ''){ temp['address1'] = urban['address1']; }
    if (urban['address2'] != ''){ temp['address2'] = urban['address2']; }
    if (urban['city'] != ''){ temp['city'] = urban['city']; }
    if (urban['state'] != ''){ temp['state'] = urban['state']; }
    if (urban['zipcode'] != ''){ temp['zipcode'] = urban['zipcode']; }
    if (urban['phone'] != ''){ temp['phone'] = urban['phone']; }
    if (urban['phone2'] != ''){ temp['phone2'] = urban['phone2']; }
    if (urban['fax'] != ''){ temp['fax'] = urban['fax']; }
    if (urban['email'] != ''){ temp['email'] = urban['email']; }
    if (urban['email2'] != ''){ temp['email2'] = urban['email2']; }
    if (urban['website'] != ''){ temp['website'] = urban['website']; }
    if (urban['facebook'] != ''){ temp['facebook'] = urban['facebook']; }
    if (urban['instagram'] != ''){ temp['instagram'] = urban['instagram']; }
    if (urban['twitter'] != ''){ temp['twitter'] = urban['twitter']; }
    if (urban['skype'] != ''){ temp['skype'] = urban['skype']; }
    if (urban['businessdays'] != ''){ temp['businessdays'] = urban['businessdays']; }
    if (urban['businesshours'] != ''){ temp['businesshours'] = urban['businesshours']; }
    if (urban['logo'] != ''){ temp['logo'] = urban['logo']; }
    if (urban['logobw'] != ''){ temp['logobw'] = urban['logobw']; }
    if (urban['logomob'] != ''){ temp['logomob'] = urban['logomob']; }
    if (urban['images'] != ''){ temp['images'] = urban['images']; }
    return temp
  }

  convert2NewIdString(pass){
    let passLength = pass.length;
    let times = Math.floor(passLength / 5);
    let newPass = '';
    let bStr = 0;
    let eStr = 0;
    for (var i = 0; i < times; ++i) {
      bStr = i * 5;
      eStr = bStr + 5;
      newPass += pass.slice(bStr, eStr)+'C-n'; 
    }
    newPass += pass.slice((times * 5), passLength); 
    return newPass;
  }

  convert2OriginalId(id){
    return id.replace(/C-n/g,'');
  }

  convertTimestamp2Date(timestamp){
    var a = new Date(timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    return month + ' ' + date + ' ' + year;
  }

  convertTimestamp2Time(timestamp){
    var a = new Date(timestamp * 1000);
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    return hour + ':' + min + ':' + sec;
  }

  /// END CONVERT

  /// BEGIN MODAL
  closeModal(){
    $('.modal-bg').removeClass('modal-backdrop fade in')
    $('.modal').removeClass('in');
    $('body').removeClass('modal-open');
    return 'none';
  }

  openModal(mId){
    $('body').addClass('modal-open');
    $('.modal').addClass('hide');
    $('.modal#'+mId).addClass('in').removeClass('hide');
    $('.modal-bg').addClass('modal-backdrop fade in')
    return 'block';
  }

  /// END MODAL


}
