import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Http, Response, Headers } from '@angular/http';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CompanyModel, CompanyService, LibsService, LocalData, MysqlService, PagerService, 
        CategoryModel, CategoryService } from '../_services';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  today: number;

  // infoCompany = [];
  company: CompanyModel = new CompanyModel();
  form: FormGroup;
  submitted = false;
  isModal = 'none';
  key = '';
  uploadUrl = this._localDB.uploadUrl;
  states = [];
  stateInput = {name:'', value: ''};

  isValidName = true;
  isValidAddress1 = true;
  isValidCity = true;
  isValidState = true;
  isValidZip = true;
  isValidPhone = true;
  isValidEmail = true;
  isValidWebsite = true;
  isValidLogo = true;
  isValidLogoBW = true;
  isValidLogoMob = true;
  
  @ViewChild('mediaFileInput') mediaFileInputVariable: ElementRef;
  medias = [];
  filesUpload = [];
  selectedFiles : FileList;
  isLoaded = false;
  images = [];
  logoImg: SafeResourceUrl;
  logoBWImg: SafeResourceUrl;
  logoMobImg: SafeResourceUrl;
  isLogo = true;
  imgType = '';
  imgCodes = [];

  requireMessage = '';
  result = '';
  selectedCatgory = '';
  categories = [];
  category: CategoryModel = new CategoryModel();
  isValidCategoryName = true;
  addCategory = true;

  pager: any = {}; // pager object
  pagedItems: any[]; // paged items
  pageSize = 12;

  constructor(
  	private companyService: CompanyService,
  	private fb: FormBuilder,
    private _libs: LibsService,
    private _mysql: MysqlService,
    private pagerService: PagerService,
    private _localDB: LocalData,
    private categoryService: CategoryService,
  ) { 
    this.company = this._libs.getLocalStorageItem('company');
    this.states = this._localDB.states;
  }

  ngOnInit() {
    this.getCategoriesList();
  }

  getCompanyInfo(){
    this.companyService.getCompanyInfo().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(company => {
      // console.log(company);
      if (company.length > 0){
        this.assign2CompanyInfo(company[0]);
        this._libs.setLocalStorageItem('company', company[0]);
      }else{
        this.company = new CompanyModel();
      }
      // if (infoCompany.length > 0){
      // }else{
      //   this._libs.unLoading();
      // }
    });
  }

  assign2CompanyInfo(info){
    // console.log(info);
    this.imgCodes = [];
    this.company = info;
    this.key = info['id'];
    // this.getMediaItem(this.company['logo'], 'logoImg');
    // this.getMediaItem(this.company['logobw'], 'logoBWImg');
    // this.getMediaItem(this.company['logomob'], 'logoMobImg');
    // for (var i = 0; i < this.company['images'].length; ++i) {
    //   this.getMediaItem(this.company['images'][i], 'imgCodes');
    // }
    // this._libs.unLoading();
  }

  onSubmit() {
  	// let name = this.form.controls['name'].value;
    let name : string = (<HTMLInputElement>document.getElementById("name")).value; 
    let address1 : string = (<HTMLInputElement>document.getElementById("address1")).value; 
    let city : string = (<HTMLInputElement>document.getElementById("city")).value; 
    // let state : string = (<HTMLInputElement>document.getElementById("state")).value; 
    let zipcode : string = (<HTMLInputElement>document.getElementById("zipcode")).value; 
    let phone : string = (<HTMLInputElement>document.getElementById("phone")).value; 
    let email : string = (<HTMLInputElement>document.getElementById("email")).value; 
    let website : string = (<HTMLInputElement>document.getElementById("website")).value; 

  	if(name == '' || name == null){
  		this.isValidName = false
  		return;
  	}else{
  		this.isValidName = true;
  	}
    if(address1 == '' || address1 == null){
      this.isValidAddress1 = false
      return;
    }else{
      this.isValidAddress1 = true;
    }
    if(city == '' || city == null){
      this.isValidCity = false
      return;
    }else{
      this.isValidCity = true;
    }
    if(this.company['state'] == '' || this.company['state'] == null){
      this.isValidState = false
      return;
    }else{
      this.isValidState = true;
    }
    if(zipcode == '' || zipcode == null){
      this.isValidZip = false
      return;
    }else{
      this.isValidZip = true;
    }
    if(phone == '' || phone == null){
      this.isValidPhone = false
      return;
    }else{
      this.isValidPhone = true;
    }
    if(email == '' || email == null){
      this.isValidEmail = false
      return;
    }else{
      this.isValidEmail = true;
    }
    if(website == '' || website == null){
      this.isValidWebsite = false
      return;
    }else{
      this.isValidWebsite = true;
    }

    // console.log(this.company);
    // console.log(this.key);
    if (this.key != ''){
      this.update(this.key, this._libs.convert2CompanyModel(this.company));
    }else{
      this.save(this.company);
    }
  }

  sanitizerUrl(value){
    return this._libs.getSantizeUrl(value);
  }

  update(key ,f){
    this.companyService.updateInfo(key, f);
    this.reset();
  }

  save(f) {
    this.companyService.createInfo(f);
    this.reset();
  }

  reset(){
    this.getCompanyInfo();
    // this.company = new ULModel();
    // this.form.reset();
  }

  delete(modalId){
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    // console.log(this.package['id']);
    this.companyService.deleteInfo();
    this.closeModal();
  }

  closeModal(){
    // this.company = new ULModel();
    this.isModal = this._libs.closeModal();
  }

  openModal(modalId){
    this.isModal = this._libs.openModal(modalId);
  }

  getState(e: any){
    this.company['state'] = e;
  }

  /// ADD MEDIA
  openMedia(modalId, type){
    this.isModal = this._libs.openModal(modalId);
    // console.log(this.medias);
    this.imgType = type;
    if (type == 'null'){
      this.isLogo = false;
    }else{
      this.isLogo = true;
    }
  // console.log(this.development);
  }

  getMediasByCategory(cId): void {
    if (this.medias.length == 0){
      this._mysql.getAllImagesByCategory(cId)
      .subscribe( (response: Response) => {
          this.medias = response.json();
          // console.log(this.medias);
          // this.setPageMedia(1);
          // console.log(this.company['id']);
          if (this.company['id'] == '' || this.company['id'] == undefined){
            this.getCompanyInfo();
          }else{
            this.assign2CompanyInfo(this.company);
          }
      });
    }
  }

  onUpload(event: any){
    this.selectedFiles = event.target.files;
    // console.log(this.selectedFiles);
    this.filesUpload = [];
    // let uploadedFiles = {};
    if(event.target.files && event.target.files.length > 0) {
      for (var f = 0; f < event.target.files.length; ++f) {
      let reader = new FileReader();
        let file = event.target.files[f];
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.filesUpload.push({'imgCode': reader.result, 'name': file['name'], 'type': file['type'], 'size': file['size'], 'uploadat': this.today});
        };
      }
      this.isLoaded = true;
    }
  }

  uploadAll() {
    if (this.selectedCatgory == ''){
      this.requireMessage = 'Please Select Category';
      return;
    }
    for (var i = 0; i < this.selectedFiles.length; ++i) {
      for (var c = 0; c < this.filesUpload.length; ++c) {
        if (this.selectedFiles[i]['name'] == this.filesUpload[c]['name']){
          this._mysql.postImage(this.selectedFiles[i], this.selectedFiles[i]['name'], this.selectedCatgory)
          .subscribe(result => {
            this.result = "Images Added.";
            // console.log(this.result);
            this.removeAllFiles();
            this.getMediasByCategory(this.selectedCatgory);
            this.closeModal();
          });
        }
      }
    }
  }

  removeFile(fn){
    this.filesUpload = this.filesUpload.filter(filesUp => filesUp.name != fn);
    if (this.filesUpload.length == 0){
      this.isLoaded = false;
      this.mediaFileInputVariable.nativeElement.value = '';
    }
    // console.log(this.filesUpload);
  }

  removeAllFiles(){
    this.filesUpload = [];
    this.mediaFileInputVariable.nativeElement.value = '';
    // this.isLoaded = false;
  }

  convertSize(size){
    return this._libs.convertSize(size);
  }

  selectedImage(id){
    for (var i = 0; i < this.images.length; ++i) {
      if (this.images[i] == id){
        return true;
      // }else{
      //   return false;
      }
    }
  }

  imageClick(name, t){
    console.log(t);
    if (t != 'null'){
      this.selectedLogo(name, t);
    }else{
      this.selectedFilesImg(name);
    }
  }
  
  selectedLogo(id, type){
    // this.logoImg = '';
    // this.logoBWImg = '';
    // this.logoMobImg = '';
    // let itemLogo = (fileName.toLowerCase()).replace('img','');
    // this.company[itemLogo] = id;
    // this.getMediaItem(id, fileName);
    if (type == 'logoImg'){
      this.company['logo'] = id;
    }else if (type == 'logoBWImg'){
      this.company['logobw'] = id;
    }else if (type == 'logoMobImg'){
      this.company['logomob'] = id;
    }
    console.log(this.company);
    this.closeModal();
  }

  selectedFilesImg(name){
    this.imgCodes = [];
    this.images = this._libs.addRemoveItem4Array(name, this.images, false);
    console.log(this.images);
    this.company['images'] = this.images;
    // for (var i = 0; i < this.images.length; ++i) {
    //   this.getMediaItem(this.images[i], 'imgCodes');
    // }
  }

  getMediaItem(id, item): void{
    // console.log(this.company);
    let tempImg = [];
    if (this.medias.length > 0){
      tempImg = this.medias.filter(media => media['id'] == id);
      if (item == 'logoImg'){
        this.logoImg = this.sanitizerUrl(tempImg[0]['imgCode']);
      }else if (item == 'logoBWImg'){
        this.logoBWImg = this.sanitizerUrl(tempImg[0]['imgCode']);
      }else if (item == 'logoMobImg'){
        this.logoMobImg = this.sanitizerUrl(tempImg[0]['imgCode']);
      }else{
        this.imgCodes.push({ 'id': id, 'imgCode': this.sanitizerUrl(tempImg[0]['imgCode']) });
      }
    }
  }  

  ///CATEGORY
  getCategoriesList(){
    this.categoryService.getCategoriesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ 'id': c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(categories => {
      this._libs.loading();
      this.categories = categories.filter(category => category['type'] == '3');
      if (this.categories.length > 0){
        this.addCategory = false;
        this.selectedCatgory = this.categories[0]['id'];
        this.getMediasByCategory(this.selectedCatgory);
      }
    });
  }

  onSubmitCategory(){
    let name : string = (<HTMLInputElement>document.getElementById("categoryName")).value; 
    if(name == '' || name == null){
      this.isValidCategoryName = false
      return;
    }else{
      this.isValidCategoryName = true;
    }
    this.category['name'] = name;
    this.category['type'] = '3';
    this.saveCategory(this.category);
  }

  categorySelected(e: any){
    this.selectedCatgory = e.target.value;
    if (this.selectedCatgory == ''){
      this.requireMessage = 'Please Select Category';
      return;
    }else{
      this.requireMessage = '';
    }
    // this.getMediasByCategory(this.selectedCatgory);
  }

  saveCategory(f){
    this.categoryService.createCategory(f);
    this.closeModal();
    this.getCategoriesList();
    (<HTMLInputElement>document.getElementById("categoryName")).value = '';
  }

  ///PAGE
  setPageMedia(page: number) {
      // get pager object from service
      this.pager = this.pagerService.getPager(this.medias.length, page, this.pageSize);
      // console.log(this.pager);
     this.pagedItems = this.medias.slice(this.pager.startIndex, this.pager.endIndex + 1);
      // console.log(this.pagedItems);
  }

}
