import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyModel, CompanyService, LibsService, LocalData } from '../../_services';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.css']
})

export class SiteLayoutComponent implements OnInit {
  company: CompanyModel = new CompanyModel();
  isLogged = false;
  uploadUrl = this._localDB.uploadUrl;
  
  constructor(
    private companyService: CompanyService,
    private _libs: LibsService,
    private _localDB: LocalData,
  ) { 
    this.company = this._libs.getLocalStorageItem('company');
  }

  ngOnInit() {
    // console.log(this.company['id']);
    if (this.company['id'] == undefined){
      this.getCompanyInfo();
    }
  	// this.company = this._localDB.infor;
    // console.log(this.infor);
  }

   getCompanyInfo(){
    this.companyService.getCompanyInfo().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(company => {
      // console.log(company);
      if (company.length > 0){
        this.company = company[0];
        this._libs.setLocalStorageItem('company', company[0]);
      }else{
        this.company = new CompanyModel();
      }
    });
  }

}
