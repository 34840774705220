import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { RoleModel } from './role.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  roleRef: AngularFireList<RoleModel> = null;

  constructor(
  	private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
  ) {
  	this.roleRef = afData.list('/roles');
  }

  getRolesList(): AngularFireList<RoleModel> {
    return this.roleRef;
  }

  getRole(id): AngularFireList<RoleModel> {
    return this.afData.list('/roles/'+id);
  }

  updateRole(key, values) {
    this.roleRef.set(key, values);
  }

  createRole(values: RoleModel): void {
    this.roleRef.push(values);
  }

  deleteRole(key: string): void {
    this.roleRef.remove(key).catch(error => this.handleError(error));
  }

  deleteAll(): void {
    this.roleRef.remove().catch(error => this.handleError(error));
  }
  
  private handleError(error) {
    console.log(error);
  }

}
