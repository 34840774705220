export class ShippingRateModel {
  type: string;
  price: string;
  value: number;
  text: string;

  constructor(){
    this.type = "";
    this.price = "";
    this.value = 0;
    this.text = "";
  }
}
