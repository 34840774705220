import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Response, Headers } from '@angular/http';
import { FormBuilder, FormGroup, FormControl , Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { AlertService, AuthenticationService, md5, LibsService,
          UserService, UserModel } from '../../_services';

import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';

import * as $ from 'jquery';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  isLogged = true;
  returnUrl: string;
  alertMessage = ''; //Enter email and password.

  user: UserModel = new UserModel();

  constructor(
  	private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private afAuth: AngularFireAuth,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
    private _libs: LibsService,
    private userService: UserService,
  ) { }

  ngOnInit() {
  	$('body').addClass('login');

  	this.loginForm = this.formBuilder.group
	  ({
      email: ['', Validators.required],
      password: ['', Validators.required]
  	});

  	// get return url from route parameters or default to '/'
    // this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  isEmail(control: FormControl): {[s: string]: boolean} {
    if (!control.value.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
        return {noEmail: true};
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

	// onLoginFormSubmit() {
	//     this.submitted = true;

	//     // stop here if form is invalid
	//     if (this.loginForm.invalid) {
	//         return;
	//     }

	//     this.loading = true;
	//     this.authenticationService.login(this.f.email.value, this.f.password.value)
	//       .pipe(first())
	//       .subscribe(
	//         data => {
	//           if (data['status'] == 'error' && !data['auth']){
	//             this.alertMessage = 'Your email or password was incorrect. Please try again';
	//             this.loading = false;
	//             this.isLogged = false;
	            
	//           }else{
	//             this.isLogged = true;
	//             this.loadData();
	//             // this.router.navigate([this.returnUrl]);
	//           }
	//         // },
	//         // error => {
	//         });
 //    }
 	
  // facebookLogin(){
  //   this.authService.doFacebookLogin()
  //   .then(res => {
  //     this.router.navigate(['/dashboard']);
 //      this._libs.go2Page('dashboard');
  //   })
  // }

  // twitterLogin(){
  //   this.authService.doTwitterLogin()
  //   .then(res => {
  //     this.router.navigate(['/dashboard']);
 //      this._libs.go2Page('dashboard');
  //   })
  // }

  // googleLogin(){
  //   this.authService.doGoogleLogin()
  //   .then(res => {
  //     this.router.navigate(['/dashboard']);
 //      this._libs.go2Page('dashboard');
  //   })
  // }

  onLoginFormSubmit(){
  	this.submitted = true;

    if (this.loginForm.invalid) {
        return;
    }
    this.loading = true;

    this.authService.doEmailLogin(this.f.email.value, this.f.password.value)
    .then(user => {
      this.isLogged = true;
      this.getCurrentUser(user.uid);
      // this.router.navigate(['/dashboard']);
    }, err => {
      // console.log(err);
      this.alertMessage = err.message;
    });
  }

  getCurrentUser(uid){
    const usersRef: firebase.database.Reference = firebase.database().ref('/users/'+uid);
    usersRef.on('value', userSnapshot => {
      this.user = this._libs.convert2UserModel(userSnapshot.val());
      // console.log(this.user);
      if (this.user['roleId'] != '-LTYayzXFuFjQKDqvWIh'){
        this.user['password'] = '';
        this.user['id'] = this._libs.convert2NewIdString(uid);
        this.user['tempPass'] = md5(this.user['password']);
        // localStorage.setItem('currentUser', JSON.stringify(this.user));
        sessionStorage.setItem('currentUser', JSON.stringify(this.user));
        this._libs.go2Page('dashboard');  
      }else{
        window.location.href = 'http://app.doleconnections.com';
      }
    });
  }

  // getCurrentUser(id){
  //   // var ref = this.afData.list.object('users/' + id);
  //   // console.log(ref);
  //   this.userService.getUsersList().snapshotChanges().pipe(
  //     map(changes =>
  //       changes.map(c => ({'id': c.payload.key, ...c.payload.val() }))
  //     )
  //   ).subscribe(users => {
  //     let tempUser = (users.filter(iuser => iuser['id'] == id))[0];
  //     // let user = this._libs.convert2UserModel(tempUser);
  //     // user['id'] = this._libs.convert2NewIdString(tempUser['id']);
  //     let user = {
  //                   about: tempUser['about'],
  //                   avatar: tempUser['avatar'],
  //                   cellphone: tempUser['cellphone'],
  //                   email: tempUser['email'],
  //                   ext: tempUser['ext'],
  //                   firstname: tempUser['firstname'],
  //                   id: this._libs.convert2NewIdString(tempUser['id']),
  //                   lastname: tempUser['lastname'],
  //                   occupation: tempUser['occupation'],
  //                   phone: tempUser['phone'],
  //                   provider: tempUser['provider'],
  //                   roleId: tempUser['roleId'],
  //                 };
  //     sessionStorage.setItem('currentUser', JSON.stringify(user));
  //     this._libs.go2Page('dashboard');   
  //     // console.log(this.packages);
  //   });
  // }

  onkeyPress(e){
	  // console.log(e.currentTarget.value);
	  if (e.key === "Enter"){
	    if (e.currentTarget.id == 'email' && e.currentTarget.value != ''){
	      $('#password').focus();
	    }else if (e.currentTarget.id == 'password' && e.currentTarget.value != ''){
	      this.onLoginFormSubmit();
	    }else{
	      $('#'+e.currentTarget.id).focus();
	      this.submitted = true;
	      this.loginForm.invalid;
	    }
	  }else{
	    this.isLogged = true;
	  }
	}

}
