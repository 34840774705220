import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { LibsService } from './_services/libs.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'My Orders Admin';
  currentUser = [];

  constructor(
    private router: Router,
    private _libs: LibsService
  ) { 
    this.currentUser = this._libs.getLocalStorageItem('currentUser');
    if (this.currentUser.length == 0){
      this._libs.go2Page('login');
    }else{
      this._libs.go2Page('dashboard');
    }
  }

  ngOnInit() {
  }
}
