import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable, of, Subject } from 'rxjs';
import { first, map, delay, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  	baseUrl: string = 'http://uploads.urbanlivingtx.com';
  	// baseUrl: string = 'http://localhost/uploads';

	constructor(
		private http: Http
	) { }

	//Upload File to PHP Server
	uploadFile(file, fName){
		let fExt = (file.name).split('.');
	    let content = new FormData();
	    content.append('file', file, fName);
	    let headers = new Headers({
	                                'Accept': 'application/json', 
	                                'Authorization': '61f53a76d009475a909b2c2ded448f1f'
	                              });
	    let options = new RequestOptions({headers: headers});
	    // return this.http.post(this.baseUrl+'/upload_file.php', content, options);
	    return this.http.post(this.baseUrl+'/upload_file.php', content, options).pipe(map((response:any) => {
	      return {
	        data: JSON.parse(response['_body']),
	      };
	    }));
	 }

}
