import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ServicesModel, ServicesService, LibsService } from '../_services';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  services = [];
  service: ServicesModel = new ServicesModel();
  form: FormGroup;
  isValidName = true;
  isValidPrice = true;
  submitted = false;
  isModal = 'none';
  modalId = '';
  order = "name";
  ascending = false;
  serviceKey = '';
  btnText = 'Submit';
  title = 'Add';
  errorNumber = '';


  constructor(
  	private servicesService: ServicesService,
  	private fb: FormBuilder,
    private _libs: LibsService,
  ) { }

  ngOnInit() {
  	this.createForm();
  	this.getServicesList();
  }

  getServicesList(){
  	this.servicesService.getServicesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(services => {
      this.services = services;
      // console.log(this.services);
    });
  }

  getAvailable(id){
    this.serviceKey = id;
    let temp = this.services.filter(service => service['id'] == id);
    return temp[0];
  }

  createForm() {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      price: new FormControl('', Validators.required)
    });
  }

  onSubmit() {
  	let name = this.form.controls['name'].value;
  	let price = this.form.controls['price'].value;
  	
  	if(name == '' || name == null){
  		this.isValidName = false
  		return;
  	}else{
  		this.isValidName = true;
  	}
  	if(price == '' || price == null){
  		this.isValidPrice = false;
        this.errorNumber = this._libs.errorNumber;
  		return;
  	}else{
  		this.isValidPrice = true;
  	}
    this.submitted = true;

    this.service = this.form.value;
    this.service['code'] = (((this.service['name']).toLowerCase()).replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g,'')).replace(/ /g,'');
    console.log(this.service);
    if (this.serviceKey){
      this.update(this.service);
    }else{
      this.save(this.service);
    }
  }

  update(f){
    this.servicesService.updateService(this.serviceKey, f);
    this.reset();
  }

  save(f) {
    this.servicesService.createService(f);
    this.reset();
  }

  reset(){
	this.title = 'Add';
	this.btnText = 'Submit';
    this.service = new ServicesModel();
    this.form.reset();
    this.serviceKey = '';
  }

  serviceEdit(id){
    this.btnText = 'Update';
    this.title = 'Edit';
    this.service = this.getAvailable(id);
  }

  serviceDelete(id, modalId){
    this.modalId = modalId;
    this.service = this.getAvailable(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    // console.log(this.service['id']);
    this.servicesService.deleteService(this.service['id']);
    this.closeModal();
  }

  closeModal(){
    this.modalId = '';
    this.service = new ServicesModel();
    this.isModal = this._libs.closeModal();
  }

}
