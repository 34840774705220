import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import { PDF2Pic } from 'pdf2pic'

import { BrochureModel, BrochureService, LibsService, LocalData, MysqlService, FileService, md5 } from '../../_services';

@Component({
  selector: 'app-brochure',
  templateUrl: './brochure.component.html',
  styleUrls: ['./brochure.component.css']
})
export class BrochureComponent implements OnInit {
  @ViewChild('filePdfInput') fileInputVariable: ElementRef;
  baseUrl: string = 'http://uploads.urbanlivingtx.com';
  pathPdfFile = md5('pdf');
  brochures = [];
  brochure: BrochureModel = new BrochureModel();
  form: FormGroup;
  isValidName = true;
  isValidFileName = true;
  submitted = false;
  isModal = 'none';
  modalId = '';
  order = "name";
  ascending = false;
  btnText = 'Add';
  title = 'Add';
  result= '';
  filePdf = File;
  brochureId = '';
  fileBrochure = '';
  isLoaded = false;

  // convertPdf2Img = new PDF2Pic({
  //   density: 100,           // output pixels per inch
  //   savename: "untitled",   // output file name
  //   savedir: "./pdfimages",    // output file location
  //   format: "png",          // output file format
  //   size: 600               // output size in pixels
  // })

  constructor(
  	private brochureService: BrochureService,
  	private fb: FormBuilder,
    private _libs: LibsService,
    private _localDB: LocalData,
    private _mysql: MysqlService,
    private fileService: FileService,
  ) { 
  	this.brochures = this._libs.getLocalStorageItem('brochures');
  }

  ngOnInit() {
  	this.createForm();
  	if (this.brochures.length == 0){
  		this.getBrochuresList();
  	}else{
      this.brochures = this.brochures.sort(this._libs.dynamicSortByName('name'));
    }
  }

  getBrochuresList(){
  	this.brochureService.getBrochuresList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(brochures => {
      this.brochures = brochures.sort(this._libs.dynamicSortByName('name'));
      sessionStorage.setItem('brochures', JSON.stringify(brochures));
      // console.log(this.brochures);
    });
  }

  getBrochure(id){
    this.brochureId = id;
    var tempBro = this.brochures.filter(brochure => brochure['id'] == id);
    return tempBro[0];
    
    // for (var p = 0; p < this.brochures.length; ++p) {
    //   if (this.brochures[p]['id'] == id){
    //     return this.brochures[p];
    //   }
    // }
  }

  // connvertPdf2Img(file){
  //   console.log(file);
  //   this.convertPdf2Img.convert(file)
  //     .then(resolve => {
  //     console.log("image converted successfully")
  //   })
  // }

  /// FUNCTIONs
  createForm() {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      filename: new FormControl('', Validators.required),
    });
  }

  uploadPdf(event) {
    this.filePdf = event.target.files[0];
    this.isLoaded = true;
    // const filePath = md5('pdf');
    let fileName = this._libs.stringLowerCaseNoSpaces(this.brochure['name']);
    if (fileName != ''){
      this.fileBrochure = fileName+'.pdf';
    }
  }

  onSubmit() {
  	let name : string = (<HTMLInputElement>document.getElementById("name")).value; 

  	if(name == '' || name == null){
  		this.isValidName = false
  		return;
  	}else{
  		this.isValidName = true;
    }
    if(!this.isLoaded){
      this.isValidFileName = false
      return;
    }else{
      this.isValidFileName = true;
      if (this.fileBrochure == ''){
        this.fileBrochure = this._libs.stringLowerCaseNoSpaces(name)+'.pdf';
      }
      this.brochure['filename'] = this.fileBrochure;
  	}
    this.brochure['code'] = (((this.brochure['name']).toLowerCase()).replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g,'')).replace(/ /g,'');
  	// console.log(this.filePdf['name']);
    this.submitted = true;
    if (this.filePdf['name'] != 'File'){
    	this.uploadFile(this.filePdf, this.fileBrochure);
    }else{
    	this.update(this.brochure);
    }
  }

  uploadFile(file, filename){
    // const filePath = md5('pdf');
    // const fileName = this._libs.stringLowerCaseNoSpaces(this.brochure['name']);
    this.fileService.uploadFile(this.filePdf, filename).subscribe(result => {
      let res = result;
      if (result['data']['result'] == 'OK'){
        this.result = res['data']['message'];
        console.log(this.brochure);
        if (this.brochureId){
          this.update(this.brochure);
        }else{
          this.save(this.brochure);
        }
      }
    });
  }

  update(f){
  	let newF = {name: f['name'], filename: f['filename'], code: f['code']};
	  this.brochureService.updateBrochure(this.brochureId, newF);
    this.reset();
  }

  save(f) {
	  this.brochureService.createBrochure(f);
    this.reset();
  }

  reset(){
	  this.title = 'Add';
	  this.btnText = 'Add';
    this.brochure = new BrochureModel();
    this.fileInputVariable.nativeElement.value = '';
    this.form.reset();
    this.brochureId = '';
    this.fileBrochure = '';
	  this.getBrochuresList();
  }

  brochureEdit(id){
    this.fileInputVariable.nativeElement.value = '';
    this.btnText = 'Update';
    this.title = 'Edit';
    this.brochure = this.getBrochure(id);
    this.fileBrochure = this.brochure['filename'];
    this.isLoaded = true;
  }

  brochureDelete(id, modalId){
    this.modalId = modalId;
    this.brochure = this.getBrochure(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    // console.log(this.video['id']);
	this.brochureService.deleteBrochure(this.brochure['id']);
    this.closeModal();
  }

  closeModal(){
    this.modalId = '';
    this.brochure = new BrochureModel();
    this.isModal = this._libs.closeModal();
  }

  ///UPLOAD PDF FILE
  

}
