import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

import { CarrierModel, CarrierService, 
		ServicesModel, ServicesService,
		CategoryService, CategoryModel, 
		MediaService, MediaModel, 
		LibsService, PagerService, MysqlService, FileService, LocalData } from '../_services';

@Component({
  selector: 'app-carriers',
  templateUrl: './carriers.component.html',
  styleUrls: ['./carriers.component.css']
})
export class CarriersComponent implements OnInit {

  @ViewChild('fileInput') fileInputVariable: ElementRef;
  @ViewChild('mediaFileInput') mediaFileInputVariable: ElementRef;
  today: number;

  order = "name";
  ascending = false;
  
  carrier: CarrierModel = new CarrierModel();
  // submitted = false;
  form: FormGroup;
  fileUpload = '';
  carriers = [];
  isValidName = true;
  isValidLogo = true;
  isValidEmail =  true;
  isValidServices = true;
  devKey = '';
  btnText = 'Submit';
  title = 'Add';
  isModal = 'none';
  modalId = '';
  result = '';
  states = [];
  // development = {};
  isMobile = false;
  isView = '';

  categories = [];
  category: CategoryModel = new CategoryModel();
  selectedCatgory = ''; //-LJL-E41herrJx1YqkH8
  filesUpload = [];
  selectedFiles: FileList;
  isLoaded = false;
  requireMessage= '';
  isMediasLoaded =  false;

  medias = [];

  services = [];
  tempServices = [];
  // mediasFilter = [];
  // selectedMedias = [];

  pager: any = {}; // pager object
  pagedItems: any[]; // paged items
  pageSize = 12;
  pagerCarriers: any = {}; // pager object
  pagedItemsCarriers: any[];
  pageSizeCarriers = 6;
  currentPageCarriers = 1;

  isGoBack = false;
  backpage = '';
  goBackTxt = '';

  editorOptions: Object = {
    placeholderText: 'Edit Your Content Here!',
    charCounterCount: false
  }

  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter description here...',
    translate: 'no'
  };

  uploadUrl = this._localDB.uploadUrl;

  constructor(
    private pagerService: PagerService,
    private categoryService: CategoryService,
    private mediaService: MediaService,
  	private servicesService: ServicesService,
  	private carrierService: CarrierService,
  	private fb: FormBuilder,
    private _libs: LibsService,
    private _localDB: LocalData,
    private _mysql: MysqlService,
    private fileService: FileService,
  ) {
    this.states = this._localDB.states;
    // this.development = this._libs.getLocalStorageItem('development');
    this.carriers = this._libs.getLocalStorageItem('carriers');
    this.isView = JSON.parse(sessionStorage.getItem('isView'));  //this._libs.getLocalStorageItem('isView');
    this.services = this._libs.getLocalStorageItem('services');
  }

  ngOnInit() {
    this.getCategoriesList();
  	if (this.services.length == 0){
  		this.getServicesList();
  	}else{
  		this.resetServices();
  	    if (this.carriers.length == 0){
  	      this.getCarriersList();
  	    }else{
          this.setPage(this.currentPageCarriers);
  	      this.getCarrierView();
  	    }
  	    // this.createForm();
  	}
    // if (_.isEmpty(this.development)){
    //   // console.log('yes Development / No back page');
    //   this.isGoBack = false;
    //   this.goBackTxt = '';
    // }else{
    //   // console.log('no Development / Yes back page');
    //   this.isGoBack = true;
    //   this.backpage = this._libs.getLocalStorageItem('backpage');
    //   this.goBackTxt = ' Go Back';
    // }    
  }

  sanitizerUrl(value){
    return this._libs.getSantizeUrl(value);
  }

  getCarriersList(){
  	this.carrierService.getCarriersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(carriers => {
      this.carriers = carriers.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('carriers', this.carriers);
      // console.log(this.carriers);
      // this.getCarrierView();
      this.setPage(this.currentPageCarriers);
    });
  }

  getCarrier(id){
    let tempCarrier = new CarrierModel();
    this.devKey = id;
    var temp = this.carriers.filter(dev => dev['id'] == id);
    // tempCarrier = this._libs.convert2CarrierModel(temp[0]);
    return temp[0];
  }

  // createForm() {
  //   this.form = this.fb.group({
  //     name: new FormControl('', Validators.required),
  //     address : '',
  //     city : '',
  //     state : '',
  //     zipcode : '',
  //     email: new FormControl('', Validators.compose([
  //                             		Validators.required,
  //                             		Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
  //                           	  ])
  //     ),
  //     phone : '',
  //     description : '',
  //     active : true,
  //     logo: new FormControl('', Validators.required)
  //   });
  // }

  onSubmit() {
  	let name : string = (<HTMLInputElement>document.getElementById("name")).value;
  	// let name = this.form.controls['name'].value;
    // let logo = this.form.controls['logo'].value;
  	
  	if(name == '' || name == null){
  		this.isValidName = false
  		return;
  	}else{
  		this.isValidName = true;
  	}
    if(this.carrier['logo'] == ''){
      this.isValidLogo = false
      return;
    }else{
      this.isValidLogo = true;
    }
    // this.submitted = true;
    if(this.carrier['features'].length == 0){
      this.isValidServices = false
      return;
    }else{
      this.isValidServices = true;
    }

    this.carrier = this._libs.convert2CarrierModel(this.carrier);
    // console.log(this.carrier);
    if (this.devKey){
      this.update(this.carrier);
    }else{
      this.save(this.carrier);
    }
  }

  update(f){
    // console.log(f);
    this.carrierService.updateCarrier(this.devKey, f);
    this.reset();
  }

  save(f) {
    this.carrierService.createCarrier(f);
    this.reset();
    if (this.isGoBack){
      this._libs.go2Page(this.backpage);
    }
  }

  reset(){
    this.getCarriersList();
    this.resetServices();
    this.title = 'Add';
    this.btnText = 'Submit';
    this.carrier = new CarrierModel();
    // this.form.reset();
    // this.fileInputVariable.nativeElement.value = '';
    this.fileUpload = '';
    this.devKey = '';
    // console.log(this.carrier);
  }

  getCarrierView(){
    // console.log(this.carriers);
    // console.log(this.isView);
    if (this.isView != undefined){
      this.carrierView(this.isView, 'view');
      this.isView = '';
    }
    sessionStorage.removeItem('isView');
  }

  carrierEdit(id){
    this.btnText = 'Update';
    this.title = 'Edit';
    this.carrier = this.getCarrier(id);
  	// console.log(this.carrier['features']);
    if (this.carrier['features'] == undefined) { this.carrier['features'] = []; }
    // console.log(this.tempServices);
    for (var i = 0; i < this.tempServices.length; ++i) {
      if (this.carrier['features'].indexOf(this.tempServices[i]['id']) >= 0){
        this.tempServices[i]['isCheck'] = true;
      }else{
        this.tempServices[i]['isCheck'] = false;
      }
    }
    // console.log(this.carrier);
    // this.form.controls['logo'].setValue(this.carrier['logo']);
  }

  carrierDelete(id, modalId){
    this.modalId = modalId;
    this.carrier = this.getCarrier(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    // console.log(this.carrier['id']);
    this.carrierService.deleteCarrier(this.devKey);
    this.devKey = '';
    this.closeModal();
  }

  carrierView(id, modalId){
    this.modalId = modalId;
    this.carrier = this.getCarrier(id);
    this.isModal = this._libs.openModal(modalId);
  }

  closeModal(){
    this.modalId = '';
    this.isModal = this._libs.closeModal();
    this.carrier = new CarrierModel();
    // this.carrier['logo'] = sessionStorage.getItem('devLogo') ? sessionStorage.getItem('devLogo') : '';
    // sessionStorage.removeItem('devLogo');
  }

  goBack(){
    this._libs.go2Page(this.backpage);
  }

  activeCarrier(id){
    var isChecked = $('#'+id).prop("checked");
    // console.log(isChecked);
    var tempCarrier = this.getCarrier(id);
    tempCarrier = this._libs.convert2CarrierModel(tempCarrier);
    tempCarrier['active'] = isChecked;
    this.carrierService.updateCarrier(id, tempCarrier);
  }

  searchCarrier(e){
    // console.log(e);
    var text = e.toLowerCase();
    if (e.length > 0){
      var temp = [];
      for (var i = 0; i < this.carriers.length; ++i) {
        var tempDevs = (this.carriers[i]['name']).toLowerCase();
        if (tempDevs != undefined && tempDevs != ''){
          if (tempDevs.indexOf(e) >= 0){
            temp.push(this.carriers[i]);
          }
        }
      }
      this.carriers = temp;
    }else{
      this.carriers = this._libs.getLocalStorageItem('carriers');
    }
    this.setPage(this.currentPageCarriers);
  }

  ///CATEGORY
  getCategoriesList(){
    this.categoryService.getCategoriesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ 'id': c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(categories => {
      this.categories = categories.filter(category => category['type'] == '4');
      // console.log(this.categories);
      this.selectedCatgory = this._libs.findIdFromArrayByName(categories, 'Carriers');
      this.getMediasByCategory(this.selectedCatgory);
    });
  }

  /// ADD MEDIA
  openMedia(modalId){
    this.modalId = modalId;
    this.isModal = this._libs.openModal(modalId);
  }

  getMediasByCategory(cId): void {
    this._mysql.getAllImagesByCategory(cId)
    .subscribe( (response: Response) => {
        this.medias = response.json();
        // this.selectedMedias = response.json();
        // console.log(this.medias);
        this.setPageMedia(1);
        this.isMediasLoaded = true;
    });
  }

  getMedia(id){
    for (var i = 0; i < this.medias.length; ++i) {
      if (this.medias[i]['id'] == id){
        return this.medias[i];
      }
    }
  }
  
  getImgCode(id){
    let devLogo = this.getMedia(id);
    if (devLogo != undefined){
      return this.sanitizerUrl(devLogo['imgCode']);
    }
  }

  selectedLogo(id){
  	// let temp = this.medias.filter(ft => ft['id'] == id);
    //  this.carrier['logo'] = temp[0]['name'];
    //  this.logoImg = temp[0]['name'];
    this.carrier['logo'] = id;
    // console.log(this.carrier);
    this.modalId = '';
    this.isModal = this._libs.closeModal();
    // sessionStorage.setItem('devLogo', id);
  }

  closeMedia(){
    this.removeAllFiles();
    this.closeModal()
  }

  closeListModal(){
    this.modalId = '';
    this.isModal = this._libs.closeModal();
    // console.log(this.carrier);
  }

  convertSize(size){
    return this._libs.convertSize(size);
  }

  onUpload(event: any){
    this.selectedFiles = event.target.files;
    // console.log(this.selectedFiles);
    this.filesUpload = [];
    // let uploadedFiles = {};
    if(event.target.files && event.target.files.length > 0) {
      for (var f = 0; f < event.target.files.length; ++f) {
      let reader = new FileReader();
        let file = event.target.files[f];
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.filesUpload.push({'imgCode': reader.result, 'name': file['name'], 'type': file['type'], 'size': file['size'], 'uploadat': this.today});
        };
      }
      this.isLoaded = true;
    }
  }

  uploadAll() {
    for (var i = 0; i < this.selectedFiles.length; ++i) {
      for (var c = 0; c < this.filesUpload.length; ++c) {
        if (this.selectedFiles[i]['name'] == this.filesUpload[c]['name']){
          // this.mediaService.createMedia(this.selectedCatgory ,this.filesUpload[i]);
          this._mysql.postImage(this.selectedFiles[i], this.selectedFiles[i]['name'], this.selectedCatgory)
          .subscribe(result => {
            this.result = "Images Added.";
            // console.log(this.result);
            this.removeAllFiles();
            this.getMediasByCategory(this.selectedCatgory);
            this.closeModal();
          });
        }
      }
    }
  }


  removeAllFiles(){
    this.filesUpload = [];
    this.mediaFileInputVariable.nativeElement.value = '';
    this.isLoaded = false;
  }

  removeFile(fn){
    this.filesUpload = this.filesUpload.filter(filesUp => filesUp.name != fn);
    if (this.filesUpload.length == 0){
      this.isLoaded = false;
      this.fileInputVariable.nativeElement.value = '';
    }
    // console.log(this.filesUpload);
  }
  
  getStateBy(id){
    for (var i = 0; i < this.states.length; ++i) {
      if (this.states[i]['id'] = id){
        return this.states[i];
      }
    }
  }

  /// SERVICES
  getServicesList(){
    this.servicesService.getServicesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(services => {
      this.services = services.sort(this._libs.dynamicSortByName('name'));
      // console.log(this.services);
      this.resetServices();
      if (this.carriers.length == 0){
      	this.getCarriersList();
      }else{
        this.setPage(this.currentPageCarriers);
        // 	this.getCarrierView();
      }
      // this.createForm();
      // console.log(this.services);
    });
  }

  resetServices(){
    this.tempServices = this.services;
    $('input[name=service]').prop('checked', false);
  }

  onServicesChange(e){
    let id = e.target.value;
    let isCheck = e.target.checked;
    // if (this.product['features'] == undefined) this.product['features'] = [];
    if (isCheck){  
      // console.log(this.product['features'].indexOf(id));
      if (this.carrier['features'].indexOf(id) < 0){
        this.carrier['features'].push(id);
      }
    }else{
      this.carrier['features'] = this.carrier['features'].filter(ft => ft != id);
    }
  }

  getServiceById(id){
    let temp = this.services.filter(ft => ft['id'] = id);
    return temp[0];
  }

  getServiceNameById(id){
    let temp = this.getServiceById(id);
    return temp['name'];
  }


  ///PAGE
  setPageMedia(page: number) {
      // get pager object from service
      this.pager = this.pagerService.getPager(this.medias.length, page, this.pageSize);
      // console.log(this.pager);
      // this.currentPage = page;
      // get current page of items
     this.pagedItems = this.medias.slice(this.pager.startIndex, this.pager.endIndex + 1);
      // console.log(this.pagedItems);
  }

  setPage(page: number) {
      // get pagerCarriers object from service
      this.pagerCarriers = this.pagerService.getPager(this.carriers.length, page, this.pageSizeCarriers);
      // console.log(this.pagerCarriers);
      this.currentPageCarriers = page;
      // get current page of items
     this.pagedItemsCarriers = this.carriers.slice(this.pagerCarriers.startIndex, this.pagerCarriers.endIndex + 1);
      // console.log(this.pagedItemsCarriers);
  }

}
