import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalData, LibsService, CategoryService, CategoryModel, MediaService, MediaModel, PagerService, MysqlService, FileService } from '../_services'
import * as firebase from 'firebase/app';
import * as $ from 'jquery';
// import * as _ from 'lodash';


@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {	  
  today: number = Date.now();
  @ViewChild('fileInput') fileInputVariable: ElementRef;
  categories = [];
  category: CategoryModel = new CategoryModel();
  medias = [];
  selectedMedias = [];
  // mediasFilter = [];
  selectedCatgory = '';
  selectedFiles: FileList;
  filesUpload = [];
  isLoaded = false;
  addMediaSection = false;
  isValidCategoryName = true;
  isMobile = false;
  isModal = 'none';
  modalId = '';
  selectedMedia = {};
  deleteMessage = '';
  isDeleteAll = false;
  deleteMeidaList = [];
  delMediaArr = [];
  isLogoBW = false;
  
  order = "name";
  ascending = false;
  
  pager: any = {}; // pager object
  pagedItems: any[]; // paged items
  pageSize = 18;
  currentPage = 0;

  result = '';
  uploadUrl = this._localData.uploadUrl;

  constructor(
    private pagerService: PagerService,
    private categoryService: CategoryService,
  	private mediaService: MediaService,
    private _libs: LibsService,
    private _localData: LocalData,
    private _mysql: MysqlService,
    private fileService: FileService,    
  ) {
    this.categories = sessionStorage.getItem('categories') ? JSON.parse(sessionStorage.getItem('categories')) : [];
  }

  ngOnInit() {
    if (this.categories.length == 0){
      this.getCategoriesList();
    }else{
      this.categories = this.categories.filter(category => category['type'] != '2');
      this.categories = this.categories.sort(this._libs.dynamicSortByName('name'));
      // this._libs.setLocalStorageItem('categories', this.categories);
      this.selectedCatgory = this.categories[0]['id'];
      this.getMediasByCategory(this.selectedCatgory);
    }
    
    // this.getMediaList();
    // console.log(this.today);
  	// $.getScript('../../../assets/global/plugins/dropzone/dropzone.min.js');
   //  $.getScript('../../../assets/pages/scripts/form-dropzone.min.js');
  }

  ngAfterViewInit(){
    window.scrollTo(0, 0);
  }

  sanitizerUrl(value){
    return this._libs.getSantizeUrl(value);
  }

  // getMediaList(){
  //   this.mediaService.getMediaList().snapshotChanges().pipe(
  //     map(changes =>
  //       changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
  //     )
  //   ).subscribe(medias => {
  //     this.medias = medias;
  //     // this.mediasFilter = medias;
  //     // console.log(this.mediasFilter);
  //     this.setPage(1);
  //   });
  // }

  /// GET ALL IMAGES FROM MYSQL BY CATEGORY
  getMediasByCategory(cId): void {
    this._mysql.getAllImagesByCategory(cId)
    .subscribe( (response: Response) => {
        this.medias = response.json();
        this.medias = this.medias.sort(this._libs.dynamicSortByName('name'));
        this.selectedMedias = response.json();
        // console.log(this.medias);
        this.setPage(1);
    });
  }

  /// GET All Images from Firebase By Category
  // getMediasByCategory(cId){
  //   // this.selectedMedias = sessionStorage.getItem('media/'+cId) ? JSON.parse(sessionStorage.getItem('media/'+cId)) : [];
  //   // if (this.selectedMedias.length == 0){
  //   // }
  //     this.mediaService.getMediaListBy(cId).snapshotChanges().pipe(
  //       map(changes =>
  //         changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
  //       )
  //     ).subscribe(medias => {
  //       this.medias = medias;
  //       this.selectedMedias = medias;
  //       // sessionStorage.setItem('media/'+cId, JSON.stringify(medias));
  //       // console.log(this.selectedMedias);
  //       this.setPage(1);
  //     });
  // }

  getMedia(id){
    for (var i = 0; i < this.selectedMedias.length; ++i) {
      if (this.selectedMedias[i]['id'] == id){
        return this.selectedMedias[i];
      }
    }
  }

  onUpload(event: any){
    this.selectedFiles = event.target.files;
    // console.log(this.selectedFiles);
  	this.filesUpload = [];
    // let uploadedFiles = {};
  	if(event.target.files && event.target.files.length > 0) {
      for (var f = 0; f < event.target.files.length; ++f) {
  		  let reader = new FileReader();
      	let file = event.target.files[f];
      	reader.readAsDataURL(file);
  	    reader.onload = () => {
          this.filesUpload.push({'imgCode': reader.result, 'name': file['name'], 'type': file['type'], 'size': file['size'], 'uploadat': this.today});
  	    	// uploadedFiles = {'imgCode': reader.result, 'name': file.name, 'type': file.type, 'size': file.size};
        //   this.checkExistFile(uploadedFiles);
        };
      }
      this.isLoaded = true;
  	}
  }

  //Handle waiting to upload each file using promise
// uploadImageAsPromise (imageFile) {
//   // return new Promise(function (resolve, reject) {
//   //     var storageRef = firebase.storage().ref("images/"+imageFile.name);

//   //     //Upload file
//   //     var task = storageRef.put(imageFile);

//   //     //Update progress bar
//   //     task.on('state_changed',
//   //         function progress(snapshot){
//   //             var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
//   //             uploader.value = percentage;
//   //         },
//   //         function error(err){

//   //         },
//   //         function complete(){
//   //             var downloadURL = task.snapshot.downloadURL;
//   //         }
//   //     );
//   // });
// }

  // checkExistFile(fs){
  //   this.today = Date.now();
  //   if (this.filesUpload.length == 0){
  //       // this.filesUpload.push({'imgCode': fs['imgCode'], 'name': fs['name'], 'type': fs['type'], 'size': fs['size'], 'uploadat': this.today, 'category' : this.selectedCatgory });
  //       this.filesUpload.push({'imgCode': fs['imgCode'], 'name': fs['name'], 'type': fs['type'], 'size': fs['size'], 'uploadat': this.today});
  //   }else{
  //     const result = this.filesUpload.find( fileUp => fileUp.name === fs.name );
  //     if (result == undefined){
  //       // this.filesUpload.push({'imgCode': fs['imgCode'], 'name': fs['name'], 'type': fs['type'], 'size': fs['size'], 'uploadat': this.today, 'category' : this.selectedCatgory});
  //       this.filesUpload.push({'imgCode': fs['imgCode'], 'name': fs['name'], 'type': fs['type'], 'size': fs['size'], 'uploadat': this.today});
  //     }
  //   }
  //  // console.log(this.filesUpload);
  // }

  convertSize(size){
  	return this._libs.convertSize(size);
  }

  uploadAll() {
    for (var i = 0; i < this.selectedFiles.length; ++i) {
      for (var c = 0; c < this.filesUpload.length; ++c) {
        if (this.selectedFiles[i]['name'] == this.filesUpload[c]['name']){
          // this.mediaService.createMedia(this.selectedCatgory ,this.filesUpload[i]);
          console.log(this.selectedFiles[i]['name']);
          let time = new Date();
          let tempExt = (this.selectedFiles[i]['name']).split('.')
          let filename = time.getTime().toString() +'.'+ tempExt[tempExt.length - 1];
          this._mysql.postImage(this.selectedFiles[i], filename, this.selectedCatgory)
          .subscribe(result => {
            this.result = "Images Added.";
            this.removeAllFiles();
            this.getMediasByCategory(this.selectedCatgory);
          });
        }
      }
    }
  }

  addMedia(){
  	if (this.addMediaSection){
  		this.addMediaSection = false;
  	}else{
  		this.addMediaSection = true;
  	}
  }

  removeFile(fn){
    this.filesUpload = this.filesUpload.filter(filesUp => filesUp.name != fn);
    if (this.filesUpload.length == 0){
      this.isLoaded = false;
      this.fileInputVariable.nativeElement.value = '';
    }
    // console.log(this.filesUpload);
  }

  removeAllFiles(){
  	this.filesUpload = [];
    this.fileInputVariable.nativeElement.value = '';
    this.isLoaded = false;
  }

  viewMedia(id, modalId){
    this.modalId = modalId;
    this.selectedMedia = this.getMedia(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    for (var d = 0; d < this.deleteMeidaList.length; ++d) {
      this._mysql.deleteImage(this.deleteMeidaList[d]['id'])
      .subscribe(result => {
        this.result = "Images deleted.";
        // this.getMediasByCategory(this.selectedCatgory);
        this.isDeleteAll = false;
        this.closeModal();
      });
      // .subscribe( (response: Response) => {
      //   this.result = response.json();
      //   console.log(this.result);
      // });
      // this.mediaService.deleteMedia(this.selectedCatgory ,this.deleteMeidaList[d]['id']); // Delete Record from Firebase
    }
  }

  getAllDeleteMedia(){
    let tempArr = [];
    for (var d = 0; d < this.delMediaArr.length; ++d) {
      for (var i = 0; i < this.medias.length; ++i) {
        if (this.medias[i]['id'] == this.delMediaArr[d] ){
          tempArr.push(this.medias[i]);
        }
      }
    }
    return tempArr;
  }

  setCheckedImages(arr){
    $('.media-item').prop("checked", false);
    for (var i = 0; i < arr.length; ++i) {
      let target = (<HTMLInputElement>document.getElementById("media-"+arr[i])); 
      target.checked = true;
    }
  }

  addDeleteList(id){
    let found = this.delMediaArr.find(x => x == id);
    if (found == undefined){
      this.delMediaArr.push(id);
    }else{
      const index = this.delMediaArr.indexOf(id);
      this.delMediaArr.splice(index, 1);
    }
    if (this.delMediaArr.length > 0){
      this.isDeleteAll = true;
    }else{
      this.isDeleteAll = false;
    }
    this.setCheckedImages(this.delMediaArr);
    // console.log(this.delMediaArr);
  }

  deleteMedia(id, modalId){
    if (id === 'all'){
      this.deleteMessage = 'Are you sure you want to delete those selected images ?';
      this.deleteMeidaList = this.getAllDeleteMedia();
    }else{
      this.selectedMedia = this.getMedia(id);
      this.deleteMeidaList.push(this.getMedia(id));
      this.deleteMessage = "Are you sure you want to delete this image '"+this.selectedMedia['name']+"' ? ";
    }
    this.modalId = modalId;
    this.isModal = this._libs.openModal(modalId);
    // console.log(this.deleteMeidaList);
  }

  clearMedia(){
    for (var i = 0; i < this.delMediaArr.length; ++i) {
      (<HTMLInputElement>document.getElementById("media-"+this.delMediaArr[i])).checked = false;
    }
    this.closeModal();
  }

  openModal(modalId){
    this.modalId = modalId;
    this.isModal = this._libs.openModal(modalId);
  }
  
  closeModal(){
    this.modalId = '';
    this.deleteMeidaList = [];
    this.delMediaArr = [];
    this.selectedMedia = new MediaModel();
    this.isModal = this._libs.closeModal();
  }

  ///CATEGORY
  getCategoriesList(){
    this.categoryService.getCategoriesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ 'id': c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(cates => {
      this.categories = cates.filter(category => category['type'] != '2');
      this.categories = this.categories.sort(this._libs.dynamicSortByName('name'));
      sessionStorage.setItem('categories', JSON.stringify(cates));
      this.selectedCatgory = cates[0]['id'];
      // this.getMediasByCategory(this.selectedCatgory);
      // console.log(this.selectedCatgory);
    });
  }
  
  categorySelected(e: any){
    this.selectedCatgory = e.target.value;
    if (this.selectedCatgory == '-LJtkPd58JyvFRU0_TtV'){
      this.isLogoBW = true;
    }else{
      this.isLogoBW = false;
    }
    if (this.filesUpload.length > 0){
      for (var i = 0; i < this.filesUpload.length; ++i) {
        this.filesUpload[i]['category'] = this.selectedCatgory;
      }
    }
    // console.log(this.filesUpload);
    this.getMediasByCategory(this.selectedCatgory);
    // this.filterWithCategory(this.selectedCatgory);
  }

  onSubmitCategory(){
    let name : string = (<HTMLInputElement>document.getElementById("categoryName")).value; 
    if(name == '' || name == null){
      this.isValidCategoryName = false
      return;
    }else{
      this.isValidCategoryName = true;
    }
    this.category['name'] = name;
    this.category['type'] = '1';
    this.saveCategory(this.category);
  }

  saveCategory(f){
    this.categoryService.createCategory(f);
    this.closeModal();
    this.getCategoriesList();
  }

  // filterWithCategory(cId){
  //   let tempArr = [];
  //   if (cId != ''){
  //     for (var i = 0; i < this.medias.length; ++i) {
  //       if (this.medias[i]['category'] == cId){
  //         tempArr.push(this.medias[i]);
  //       }
  //     }
  //     this.mediasFilter = tempArr;
  //   // return tempArr;
  //   }else{
  //     this.mediasFilter = this.medias;
  //    // this.getMediaList();
  //   }
  // }

  ///PAGER
  setPage(page: number) {
      // get pager object from service
      this.pager = this.pagerService.getPager(this.selectedMedias.length, page, this.pageSize);
      // console.log(this.pager);
      this.currentPage = page;
      // get current page of items
     this.pagedItems = this.selectedMedias.slice(this.pager.startIndex, this.pager.endIndex + 1);
      // console.log(this.pagedItems);
  }

}
