export class CarrierModel {
  name: string;
  address: string;
  city: string;
  zipcode: string;
  state: string;
  email: string;
  phone: string;
  description: string;
  logo: string;
  active: boolean;
  features: any[];


  constructor(){
    this.name = "";
    this.address = "";
    this.city = "";
    this.zipcode = "";
    this.state = "";
    this.email = "";
    this.phone = "";
    this.description = "";
    this.logo = "";
    this.active = true;
    this.features = [];
  }
}
