import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VideoModel, VideoService, LibsService, LocalData, MysqlService, PagerService } from '../../_services';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  @ViewChild('fileThumbInput') fileInputVariable: ElementRef;
  matterportUrl: SafeHtml = '';

  order = "name";
  ascending = false;

  videos = [];
  // virtualvideos = [];
  videoTypes = [];
  video: VideoModel = new VideoModel();
  form: FormGroup;
  isValidName = true;
  isValidURL = true;
  isValidType = true;
  isValidThumb = true;
  submitted = false;
  isModal = 'none';
  modalId = '';
  key = '';
  btnText = 'Add';
  title = 'Add';
  selectedVideoType = '';
  selectedVideoTypeName = 'Video / Virtual Tour';
  thumbFile: File;
  isLoaded = false;
  result = '';
  videoThumb = '';

  pager: any = {}; // pager object
  pagedItems: any[]; // paged items
  pageSize = 10;

  constructor(
  	private videoService: VideoService,
  	private fb: FormBuilder,
    private _libs: LibsService,
    private _localDB: LocalData,
    private _mysql: MysqlService,
    private pagerService: PagerService,
  ) { 
    this.matterportUrl = this.getSantizeUrl(this._localDB.matterportUrl);
    this._libs.loading();
    this.videos = this._libs.getLocalStorageItem('videos');
  }

  getSantizeHTML(url){
    return this._libs.getSantizeHTML(url);
  }

  getSantizeUrl(url){
    return this._libs.getSantizeUrl(url);
  }

  getUrl(id){
    return  this.getSantizeUrl(this._localDB.matterportUrl+id);
  }

  ngOnInit() {
  	this.videoTypes = this._localDB.videoType;
  	// this.selectedVideoTypeName = this.videoTypes[0]['name'];
  	// console.log(this.selectedVideoTypeName);

  	this.createForm();
  	if (this.videos.length == 0){
  		this.getVideosList();
  	}
  }

  ///VIDEO / VIRTUAL VIDEO
  getVideosList(): void {
    this._mysql.getVideos()
    .subscribe( (response: Response) => {
        this.videos = response.json();
        // console.log(this.medias);
        this.setPage(1);
        this._libs.unLoading();
        this.isLoaded = true;
    });
  }

  getVideosListByType(type){
    this._mysql.getVideosByType(type)
    .subscribe( (response: Response) => {
        this.videos = response.json();
        // console.log(this.medias);
        this.setPage(1);
        this._libs.unLoading();
        this.isLoaded = true;
        
    });
  }
  
  // getVideosList(){ // Get Videos from Firebase
  // 	this.videoService.getVideosList().snapshotChanges().pipe(
  //     map(changes =>
  //       changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
  //     )
  //   ).subscribe(videos => {
  //     this.videos = videos;
  //     sessionStorage.setItem('videos', JSON.stringify(videos));
  //     this.listFiles = this.videos;
  //     // console.log(this.videos);
  //   });
  // }

  getVideo(id){
    this.key = id;
    for (var p = 0; p < this.videos.length; ++p) {
      if (this.videos[p]['id'] == id){
        return this.videos[p];
        ;
      }
    }
  }

  /// FUNCTIONs
  createForm() {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      url: new FormControl('', Validators.required),
      thumb: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
    });
  }

  changeVideoType(e: any){
    this._libs.loading()
  	let typeId = ((e.currentTarget.id).replace('video-','').split('-'))[0];
  	// let typeId = (e.currentTarget.value);
  	for (var i = 0; i < this.videoTypes.length; ++i) {
  		if (this.videoTypes[i]['id'] == typeId){
  			this.selectedVideoType = this.videoTypes[i]['id'];
  			this.selectedVideoTypeName = this.videoTypes[i]['name'];
  		}
  	}
    this.video['type'] = this.selectedVideoType;
		this.getVideosListByType(this.selectedVideoType);
  	// console.log(typeId);
  }

  uploadThumb(event: any){
    this.thumbFile = event.target.files[0];
    // // console.log(this.selectedFiles);
    if(event.target.files && event.target.files.length > 0) {
      let reader = new FileReader();
      reader.readAsDataURL(this.thumbFile);
      reader.onload = () => {
        this.videoThumb =  (reader.result).toString();
      }
      this.isLoaded = true;
    }
  }

  onSubmit() {
  	let name : string = (<HTMLInputElement>document.getElementById("name")).value; 
    let url : string = (<HTMLInputElement>document.getElementById("url")).value; 

  	if(this.selectedVideoType == '' || this.selectedVideoType == null){
  		this.isValidType = false
  		return;
  	}else{
  		this.isValidType = true;
  	}
  	if(name == '' || name == null){
  		this.isValidName = false
  		return;
  	}else{
  		this.isValidName = true;
  	}
  	if(url == '' || url == null){
  		this.isValidURL = false
  		return;
  	}else{
      this.video['url'] = (this.video['url'].replace('&mls=1','')).replace('https://my.matterport.com/show/?m=','');
  		this.isValidURL = true;
  	}
    if(this.videoThumb == '' || this.videoThumb == null){
      this.isValidThumb = false
      return;
    }else{
      this.isValidThumb = true;
    }

    this.submitted = true;
    // console.log(this.video);
    // console.log(this.thumbFile);
    if (this.key){
      if (this.thumbFile == undefined){
        this.update(this.video);
      }else{
        this.updateFile(this.video);
      }
    }else{
      this.save(this.video);
    }
  }

  update(eForm){
    this._mysql.updateVideo(eForm).subscribe(res => {
      let result = res;
      let id = Number(res['id']);
      if (id > 0 && res['data']['result'] == 'OK'){
        this.result = res['data']['message'];
        this.reset();
      }
    });
  }

  updateFile(eForm){
    this._mysql.updateVideoFile(this.thumbFile, eForm).subscribe(res => {
      let result = res;
      let id = Number(res['id']);
      if (id > 0 && res['data']['result'] == 'OK'){
        this.result = res['data']['message'];
        this.reset();
      }
    });
  }

  save(eForm){
    this._mysql.postVideo(this.thumbFile, eForm).subscribe(res => {
      let result = res;
      let id = Number(res['data']['id']);
      if (id > 0){
        this.result = res['data']['result'];
        this.reset();
      }
    });
  }

  // update(f){ // Update to Firebase
  //   let newF = {name: f['name'], url: f['url'], thumb: f['thumb'], type: f['type']};
  //   if (this.selectedVideoType == '1'){
  //     this.videoService.updateVideo(this.key, newF);
  //   }else{
  //     this.videoService.updateVirtualVideo(this.key, newF);
  //   }
  //   this.reset();
  // }

  // save(f) { // Save to Firebase
  // 	if (this.selectedVideoType == '1'){
  //   	this.videoService.createVideo(f);
  // 	}else{
  //   	this.videoService.createVirtualVideo(f);
  // 	}
  //   this.reset();
  // }

  reset(){
    this.isValidName = true;
    this.isValidURL = true;
    this.isValidType = true;
    this.isValidThumb = true;
	  this.title = 'Add';
	  this.btnText = 'Add';
    this.video = new VideoModel();
    this.videoThumb = '';
    this.fileInputVariable.nativeElement.value = '';
    this.form.reset();
    this.key = '';
		this.getVideosList();
  }

  videoEdit(id){
    this.btnText = 'Update';
    this.title = 'Edit';
    this.video = this.getVideo(id);
    this.selectedVideoType = this.video['type'];
    this.videoThumb = this.video['thumb'];
    // console.log(this.video);
  }

  videoDelete(id, modalId){
    this.modalId = modalId;
    this.video = this.getVideo(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    this._mysql.deleteVideo(this.video['id']).subscribe(res => {
      let result = res;
      if (res['data']['result'] == 'OK'){
        this.result = res['data']['message'];
        this.closeModal();
        this.getVideosList();
      }
    });
  }

  closeModal(){
    this.modalId = '';
    this.video = new VideoModel();
    this.isModal = this._libs.closeModal();
  }

  ///PAGER
  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.videos.length, page, this.pageSize);
    // console.log(this.pager);
   this.pagedItems = this.videos.slice(this.pager.startIndex, this.pager.endIndex + 1);
    // console.log(this.pagedItems);
  }

}
