import { Component, OnInit } from '@angular/core';

import { AuthenticationService, LibsService } from '../../_services';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private _libs: LibsService,
  ) { 
  	// sessionStorage.removeItem('currentUser');
  }

  ngOnInit() {
  }

  logout() {
	sessionStorage.clear();
    this.authService.doSignOut();
    this._libs.go2Page('login');    
  }
}
