import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { PaymentModel } from './payment.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  paymentRef: AngularFireList<PaymentModel> = null;

  constructor(
  	private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
  ) {
  	this.paymentRef = afData.list('/payments');
  }

  getPaymentsList(): AngularFireList<PaymentModel> {
    return this.paymentRef;
  }

  getPayment(id): AngularFireList<PaymentModel> {
    return this.afData.list('/payments/'+id);
  }

  updatePayment(key, values) {
    this.paymentRef.set(key, values);
  }

  createPayment(values: PaymentModel): void {
    this.paymentRef.push(values);
  }

  deletePayment(key: string): void {
    this.paymentRef.remove(key).catch(error => this.handleError(error));
  }

  deleteAll(): void {
    this.paymentRef.remove().catch(error => this.handleError(error));
  }
  
  private handleError(error) {
    console.log(error);
  }
}
