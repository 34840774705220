import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ShippingRateModel, ShippingRateService, LibsService } from '../../_services';

@Component({
  selector: 'app-shipping-rate',
  templateUrl: './shipping-rate.component.html',
  styleUrls: ['./shipping-rate.component.css']
})
export class ShippingRateComponent implements OnInit {
  shippingRates = [];
  shippingRate: ShippingRateModel = new ShippingRateModel();
  form: FormGroup;
  isValidType = true;
  isValidPrice = true;
  isValidValueText = true;
  submitted = false;
  isModal = 'none';
  modalId = '';
  order = "name";
  ascending = false;
  key = '';
  btnText = 'Add';
  title = 'Add';

  constructor(
  	private shippingRateService: ShippingRateService,
  	private fb: FormBuilder,
    private _libs: LibsService,
  ) { 
    this.shippingRates = this._libs.getLocalStorageItem('shippingRates');
  }

  ngOnInit() {
  	this.createForm();
  	this.getShippingRatesList();
    // console.log(this.shippingRate);
    // if (this.shippingRate['value'] == 0){
    // 	this.shippingRate['text'] = 'Free';
    // }
  }

  getShippingRatesList(){
  	this.shippingRateService.getShippingRatesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(shippingRates => {
      this.shippingRates = shippingRates.sort(this._libs.dynamicSortByName('value'));
      this._libs.setLocalStorageItem('shippingRates', this.shippingRates);
      console.log(this.shippingRates);
    });
  }

  getShippingRate(id){
    this.key = id;
    let temp = this.shippingRates.filter(shippingRate => shippingRate['id'] == id);
    return temp[0];
  }

  createForm() {
    this.form = this.fb.group({
      type: new FormControl('', Validators.required),
      price: new FormControl(),
      text: new FormControl(),
    });
  }

  onConvert2Value(e){
  	// console.log(e.target.value);
  	this.shippingRate['text'] = e.target.value;
	if (e.target.value == 0){
		this.shippingRate['text'] = 'Free'
	}
  }

  onSubmit() {
  	let type = this.form.controls['type'].value;
  	let price = this.form.controls['price'].value;
  	// let value = this.form.controls['value'].value;
  	let text = this.form.controls['text'].value;
  	
  	if(type == '' || type == null){
  		this.isValidType = false
  		return;
  	}else{
  		this.isValidType = true;
  	}

  	if(price == '' || price == null){
  		this.isValidPrice = false
  		return;
  	}else{
  		this.isValidPrice = true;
  	}

    sessionStorage.removeItem('shippingRates');
    this.shippingRate['value'] = Number(price); // = this.form.value;
    console.log(this.shippingRate);
    if (this.key){      
      this.update(this.shippingRate);
    }else{
      this.save(this.shippingRate);
    }
  }

  update(f){
    this.shippingRateService.updateShippingRate(this.key, f);
    this.reset();
  }

  save(f) {
    this.shippingRateService.createShippingRate(f);
    this.reset();
  }

  reset(){
	this.title = 'Add';
	this.btnText = 'Add';
    this.shippingRate = new ShippingRateModel();
    this.form.reset();
    this.getShippingRatesList();
    this.key = '';
  }

  shippingRateEdit(id){
    this.btnText = 'Update';
    this.title = 'Edit';
    this.shippingRate = this.getShippingRate(id);
  }

  shippingRateDelete(id, modalId){
    this.modalId = modalId;
    this.shippingRate = this.getShippingRate(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    // console.log(this.shippingRate['id']);
    this.shippingRateService.deleteShippingRate(this.shippingRate['id']);
    this.closeModal();
  }

  closeModal(){
    this.modalId = '';
    this.shippingRate = new ShippingRateModel();
    this.isModal = this._libs.closeModal();
  }


}
