export class MenuModel {
  name: string;
  icon: string;
  link: string;
  submenu: boolean;
  order: number;

  constructor(){
    this.name = '';
    this.icon = '';
    this.link = '';
    this.submenu = false;
    this.order = 0;
  }
}

export class SubmenuModel {
  menuId: string;
  name: string;
  icon: string;
  link: string;
  submenuL2: boolean;
  isSubmenuL2: boolean;
  order: number;
  
  constructor(){
    this.menuId = '';
    this.name = '';
    this.icon = '';
    this.link = '';
    this.submenuL2 = false;
    this.isSubmenuL2 = false;
    this.order = 0;
  }
}
