import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';


import { ProductModel, ProductService, 
		 LibsService, PagerService, MysqlService, FileService, LocalData,
		 CategoryService, CategoryModel, 
     ServicesModel, ServicesService, 
     TypeService,
		 MediaService, MediaModel } from '../_services';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  @ViewChild('fileInput') fileInputVariable: ElementRef;
  @ViewChild('mediaFileInput') mediaFileInputVariable: ElementRef;
  today: number;
  uploadUrl = this._localDB.uploadUrl;

  product: ProductModel = new ProductModel();
  // submitted = false;
  form: FormGroup;
  fileUpload = '';
  products = [];
  // productsFull = [];

  isValidName = true;
  isValidSKU = true;
  isValidDescription = true;
  isValidPrice = true;
  isValidImage = true;
  isValidCategory = true;
  isValidServices = true;
  isValidType = true;
  isValidQTY = true;
  isValidSalePrice = true; 

  errorEmail = '';
  errorNumber = '';
  productKey = '';
  btnText = 'Submit';
  title = 'Add';
  isModal = 'none';
  modalId = '';
  result = '';
  states = [];
  development = {};
  isMobile = false;
  isAct = 'No';
  isError = false;
  isSaleName = 'No';
  isFeatureName = 'No';

  categories = [];
  category: CategoryModel = new CategoryModel();
  selectedCatgory = ''; //-LJL-E41herrJx1YqkH8
  filesUpload = [];
  selectedFiles: FileList;
  isLoaded = false;
  requireMessage = '';
  resultMessage = '';
  // isMediasLoaded =  false;
  isValidCategoryName = true;

  medias = [];
  imgCodes = [];
  images = [];
  services = [];
  tempServices = [];
  types = [];
  // mediasFilter = [];
  // selectedMedias = [];

  pager: any = {}; // pager object
  pagedItems: any[]; // paged items
  pageSize = 12;
  pagerProducts: any = {}; // pager object
  pagedItemsProducts: any[];
  pageSizeProducts = 6;
  isView = '';
  // isGoBack = false;
  // // backpage = '';
  // goBackTxt = '';

  editorOptions: Object = {
    placeholderText: 'Edit Your Content Here!',
    charCounterCount: false
  }

  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter description here...',
    translate: 'no'
  };

  editorSpecConfig = {
    editable: true,
    spellcheck: false,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter specifications here...',
    translate: 'no'
  };

  constructor(
    private pagerService: PagerService,
    private categoryService: CategoryService,
    private mediaService: MediaService,
    private productService: ProductService,
    private servicesService: ServicesService,
  	private typeService: TypeService,
  	private fb: FormBuilder,
    private _libs: LibsService,
    private _localDB: LocalData,
    private _mysql: MysqlService,
    private fileService: FileService,
  ) {
    this.types = this._libs.getLocalStorageItem('types');
    this.products = this._libs.getLocalStorageItem('products');
    this.categories = this._libs.getLocalStorageItem('categories');
    this.services = this._libs.getLocalStorageItem('services');
    this.isView = JSON.parse(sessionStorage.getItem('isView'));
  }

  ngOnInit() {
    this.getCategoriesList();
    this.createForm();

    // if (this.categories.length == 0){
    // }else{
    //   this.selectedCatgory = this._libs.findIdFromArrayByName(this.categories, 'Products');
    //   if (this.services.length == 0){
    //     this.getServicesList();
    //   }else{
    //     console.log(this.services);
    //     this.resetServices();
    //     if (this.products.length == 0){
    //       this.getProductsList();
    //     }else{
    //       this.products = this.products.sort(this._libs.dynamicSortByName('name'));
    //       this.getProductView();
    //       this.setPage(1);
    //     }
    //   }
    // }
  }

  createForm() {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      sku: new FormControl('', Validators.required),
      price: new FormControl('', Validators.required),
      salePrice: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      overview: new FormControl('', Validators.required),
      specifications: new FormControl('', Validators.required),
      image: new FormControl('', Validators.required),
      categoryId: new FormControl('', Validators.required),
      quantity: new FormControl('',  Validators.required),
      features: new FormControl([],  Validators.required),
      type: new FormControl('',  Validators.required),
      active: true,
      isSale: false,
      isFeature: false,
    });
  }

  sanitizerHTML(val){
    return this._libs.getSantizeHTML(val);
  }

  sanitizerUrl(value){
    return this._libs.getSantizeUrl(value);
  }

  onSubmit() {
    let name : string = (<HTMLInputElement>document.getElementById("name")).value;
    let sku : string = (<HTMLInputElement>document.getElementById("sku")).value;
    let price : string = (<HTMLInputElement>document.getElementById("price")).value;
    let quantity : string = (<HTMLInputElement>document.getElementById("quantity")).value;
    // let type : string = (<HTMLInputElement>document.getElementById("type")).value;
    let type = this.form.controls['type'].value;
    let isSale = this.form.controls['isSale'].value;
    let isFeature = this.form.controls['isFeature'].value;
    let description : string = (<HTMLInputElement>document.getElementById("description")).value;
    let overview : string = (<HTMLInputElement>document.getElementById("overview")).value;
    let specifications : string = (<HTMLInputElement>document.getElementById("specifications")).value;
    // console.log(type);

  	if(name == '' || name == null){
  		this.isValidName = false
  		return;
  	}else{
  		this.isValidName = true;
  	}
    if(sku == '' || sku == null){
      this.isValidSKU = false
      return;
    }else{
      this.isValidSKU = true
    }
    if(price == '' || price == null){
      this.isValidPrice = false
      return;
    }else{
      // console.log(this._libs.isZipCode(price));
      if (!this._libs.isNumber(price)){
        (<HTMLInputElement>document.getElementById("price")).value = '';
        this.errorNumber = this._libs.errorNumber;
        this.isValidPrice = false
        return;
      }
      this.isValidPrice = true;
    }
    if(quantity == '' || quantity == null){
      this.isValidQTY = false
      return;
    }else{
      this.isValidQTY = true;
    }
    if(type == '' || type == null){
      this.isValidType = false
      return;
    }else{
      this.isValidType = true;
    }
    if(description == '' || description == null){
      this.isValidDescription = false
      return;
    }else{
      this.isValidDescription = true;
    }
    if(this.product['image'].length == 0){
      this.isValidImage = false
      this.resultMessage = "Select Image";
      return;
    }else{
      this.resultMessage = "";
      this.isValidImage = true;
    }
    // console.log(this.product['features']);
    if(this.product['features'].length == 0){
      this.isValidServices = false
      return;
    }else{
      this.isValidServices = true;
    }
    if (isSale){
      if(this.product['salePrice'].length == 0){
        this.isValidSalePrice = false
        return;
      }else{
        this.isValidSalePrice = true;
      }
    }
    // console.log(isFeature);

    this.product['categoryId'] = this.selectedCatgory;

    this.product = this._libs.convert2ProductModel(this.product);
    // console.log(this.product);
    if (this.productKey){
      this.update(this.product);
    }else{
      this.save(this.product);
    }
  }

  update(f){
    this.productService.updateProduct(this.productKey, f);
    this.reset();
  }

  save(f) {
    this.productService.createProduct(f);
    this.reset();
  }

  reset(){
    this.resetServices();
    this.getProductsList();
    this.title = 'Add';
    this.btnText = 'Submit';
    this.product = new ProductModel();
    // this.form.reset();
    this.fileUpload = '';
    this.productKey = '';
    this.imgCodes = [];
    this.images = [];
    // console.log(this.product);
  }

  getProductView(){
    // this.setPage(this.currentPageDevelopers);
    // console.log(this.developers);
    // console.log(this.isView);
    if (this.isView != undefined){
      this.productView(this.isView, 'view');
    }
    sessionStorage.removeItem('isView');
  }

  productEdit(id){
    this.btnText = 'Update';
    this.title = 'Edit';
    this.product = new ProductModel();
    this.product = this.getProductBy(id);
    if (this.product['features'] == undefined) { this.product['features'] = []; }
    if (this.product['quantity'] == undefined) { this.product['quantity'] = ''; }
    if (this.product['type'] == undefined) { this.product['type'] = ''; }
    if (this.product['isSale'] == undefined) { this.product['isSale'] = false; }
    if (this.product['isFeature'] == undefined) { this.product['isFeature'] = false; }
    if (this.product['salePrice'] == undefined) { this.product['salePrice'] = ''; }
    
    // console.log(this.tempServices);
    for (var i = 0; i < this.tempServices.length; ++i) {
      if (this.product['features'].indexOf(this.tempServices[i]['id']) >= 0){
        this.tempServices[i]['isCheck'] = true;
      }else{
        this.tempServices[i]['isCheck'] = false;
      }
    }
    this.selectedCatgory = this.product['categoryId'];
    this.getProductView();
  }

  productDelete(id, modalId){
    this.modalId = modalId;
    this.product = this.getProductBy(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    // console.log(this.product['id']);
    this.productService.deleteProduct(this.product['id']);
    this.closeModal();
    this.getProductsList();
  }

  productView(id, modalId){
    this.modalId = modalId;
    this.product = this.getProductBy(id);
    this.isModal = this._libs.openModal(modalId);
  }
  
  openMedia(modalId){
    if (this.selectedCatgory == ''){
      this.requireMessage = 'Please Select Category';
      return;
    }else{
      this.requireMessage = '';
      this.getMediasByCategory(this.selectedCatgory);
    }
    this.modalId = modalId;
    this.isModal = this._libs.openModal(modalId);
  }

  closeMedia(){
    this.removeAllFiles();
    this.closeModal()
  }

  openModal(modalId){
    this.modalId = modalId;
    this.isModal = this._libs.openModal(modalId);
  }

  closeModal(){
    this.modalId = '';
    this.isModal = this._libs.closeModal();
  }

  closeModalView(){
    this.closeModal();
    this.reset();
  }

  convertSize(size){
    return this._libs.convertSize(size);
  }

  getState(e: any){
    this.product['state'] = e;
  }

  isOnSale(e: any){
    if (e.target.checked){
      this.isSaleName = 'Yes'
    }else{
      this.isSaleName = 'No'
    }
    this.product['isSale'] = e.target.checked;
  }

  isFeature(e: any){
    if (e.target.checked){
      this.isFeatureName = 'Yes'
    }else{
      this.isFeatureName = 'No'
    }
    this.product['isFeature'] = e.target.checked;
  }

  /// PHONE TYPE
  getTypesList(){
    this.typeService.getTypesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(types => {
      this.types = types.sort(this._libs.dynamicSortByName('order'));
      this._libs.setLocalStorageItem('types', this.types);
      // this.getProductsFullInfo();
      // console.log(this.products);
      if (this.products.length == 0){
        this.getProductsList();
      }else{
        this.products = this.products.sort(this._libs.dynamicSortByName('name'));
        this.getProductView();
        this.setPage(1);
      }
    });
  }

  ///PRODUCTs
  getProductsList(){
    this.productService.getProductsList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(products => {
      this.products = products.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('products', this.products);
      // this.getProductsFullInfo();
      // console.log(this.products);
      this.getProductView();
      this.setPage(1);
    });
  }

  getProductBy(id){
    this.productKey = id;
    var temp = this.products.filter(ft => ft['id'] == id);
    return temp[0];
  }

  activeProduct(e: any){
    // console.log(e.target.value);
    if (e.target.checked){
      this.isAct = 'Yes'
    }else{
      this.isAct = 'No'
    }
    this.product['active'] = e.target.checked;
  }

  selectProduct(e){
    let id = e.target.value;
    let isCheck = e.target.checked;
    let tempProd = this.products.filter(ft => ft['id'] == id);
    tempProd = tempProd[0];
    tempProd['active'] = isCheck;
    let product = this._libs.convert2ProductModel(tempProd);
    this.productService.updateProduct(id, product);
  }

  ///CATEGORY
  getCategoriesList(){
    this.categoryService.getCategoriesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ 'id': c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(categories => {
      this.categories = categories.filter(category => category['type'] == '1');
      this._libs.setLocalStorageItem('categories', this.categories);
      this.selectedCatgory = this._libs.findIdFromArrayByName(this.categories, 'Products');
      if (this.services.length == 0){
        this.getServicesList();
      }else{
        this.resetServices();
        if (this.types.length == 0){
          this.getTypesList();
        }else{
          if (this.products.length == 0){
            this.getProductsList();
          }else{
            this.products = this.products.sort(this._libs.dynamicSortByName('name'));
            this.getProductView();
            this.setPage(1);
          }
        }
        
      }
      // console.log(this.categories);
    });
  }

  categorySelected(e: any){
    this.selectedCatgory = e.target.value;
    if (this.selectedCatgory != ''){
      this.requireMessage = '';
      // console.log(this.selectedCatgory);
      this.getMediasByCategory(this.selectedCatgory);
    }else{
      this.requireMessage = 'Please Select Category';
      return;
    }
  }

  onSubmitCategory(){
    let name : string = (<HTMLInputElement>document.getElementById("categoryName")).value; 
    if(name == '' || name == null){
      this.isValidCategoryName = false
      return;
    }else{
      this.isValidCategoryName = true;
    }
    this.category['name'] = name;
    this.category['type'] = '4';
    this.saveCategory(this.category);
  }

  saveCategory(f){
    this.categoryService.createCategory(f);
    this.closeModal();
    this.getCategoriesList();
  }

  /// ADD MEDIA
  getMediasByCategory(cId): void {
    this._mysql.getAllImagesByCategory(cId)
    .subscribe( (response: Response) => {
        this.medias = response.json();
        // console.log(this.medias);
        this.setPageMedia(1);
        // this.isMediasLoaded = true;
        if (this.productKey == ''){
          // this._libs.unLoading();
        }
    });
  }

  getMedia(id){
    for (var i = 0; i < this.medias.length; ++i) {
      if (this.medias[i]['id'] == id){
        return this.medias[i];
      }
    }
  }
  
  getImgCode(id){
    let devLogo = this.getMedia(id);
    if (devLogo != undefined){
      return this.sanitizerUrl(devLogo['imgCode']);
    }
  }

  selectedFilesImg(id){
    let tempImg = this.medias.filter(ft => ft['id'] == id);
    this.product['image'] = tempImg[0]['name'] //.toString();
    this.closeModal();
    // console.log(this.images);
  }

  selectedImage(id){
    for (var i = 0; i < this.images.length; ++i) {
      if (this.images[i] == id){
        return true;
      }
    }
  }

  removeImage(){
    this.product['image'] = '';
  }

  getMediaItem(id, item): void{
    this._mysql.getImageById(id)
    .subscribe( (response: Response) => {
        let fileImg = response.json();
          this.imgCodes.push({ 'id': id, 'imgCode': this.sanitizerUrl(fileImg[0]['imgCode']) });
    });
  }  

  onUpload(event: any){
    this.selectedFiles = event.target.files;
    // console.log(this.selectedFiles);
    this.filesUpload = [];
    // let uploadedFiles = {};
    if(event.target.files && event.target.files.length > 0) {
      for (var f = 0; f < event.target.files.length; ++f) {
      let reader = new FileReader();
        let file = event.target.files[f];
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.filesUpload.push({'imgCode': reader.result, 'name': file['name'], 'type': file['type'], 'size': file['size'], 'uploadat': this.today});
        };
      }
      this.isLoaded = true;
    }
  }

  uploadAll() {
    if (this.selectedCatgory == ''){
      this.isError = true;
      this.requireMessage = 'Please Select Category';
      return;
    }else{
      this.isError = false;
      this.requireMessage = '';
    }
    for (var i = 0; i < this.selectedFiles.length; ++i) {
      for (var c = 0; c < this.filesUpload.length; ++c) {
        if (this.selectedFiles[i]['name'] == this.filesUpload[c]['name']){
          // this.mediaService.createMedia(this.selectedCatgory ,this.filesUpload[i]);
          this._mysql.postImage(this.selectedFiles[i], this.selectedFiles[i]['name'], this.selectedCatgory)
          .subscribe(result => {
            this.result = "Images Added.";
            console.log(this.result);
            this.removeAllFiles();
            // this.getMediasByCategory(this.selectedCatgory);
            this.closeModal();
          });
        }
      }
    }
  }

  removeAllFiles(){
    this.filesUpload = [];
    this.mediaFileInputVariable.nativeElement.value = '';
    this.isLoaded = false;
  }

  removeFile(fn){
    this.filesUpload = this.filesUpload.filter(filesUp => filesUp.name != fn);
    if (this.filesUpload.length == 0){
      this.isLoaded = false;
      this.fileInputVariable.nativeElement.value = '';
    }
    // console.log(this.filesUpload);
  }
  
  getStateBy(id){
    for (var i = 0; i < this.states.length; ++i) {
      if (this.states[i]['id'] = id){
        return this.states[i];
      }
    }
  }

  /// SERVICES
  getServicesList(){
    this.servicesService.getServicesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(services => {
      this.services = services.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('services', this.services);
      // console.log(this.services);
      this.resetServices();
      if (this.types.length == 0){
        this.getTypesList();
      }else{
        if (this.products.length == 0){
          this.getProductsList();
        }else{
          this.products = this.products.sort(this._libs.dynamicSortByName('name'));
          this.getProductView();
          this.setPage(1);
        }
      }
      // console.log(this.services);
    });
  }

  resetServices(){
    this.tempServices = this.services;
    $('input[name=service]').prop('checked', false);

    // for (var i = 0; i < this.tempServices.length; ++i) {
    //     this.tempServices[i]['isCheck'] = false;
    // }
    // console.log(this.tempServices);
  }

  onServicesChange(e){
    let id = e.target.value;
    let isCheck = e.target.checked;
    // if (this.product['features'] == undefined) this.product['features'] = [];
    if (isCheck){  
      // console.log(this.product['features'].indexOf(id));
      if (this.product['features'].indexOf(id) < 0){
        this.product['features'].push(id);
      }
    }else{
      this.product['features'] = this.product['features'].filter(ft => ft != id);
    }
  }

  getServiceById(id){
    let temp = this.services.filter(ft => ft['id'] = id);
    return temp[0];
  }

  getServiceNameById(id){
    let temp = this.getServiceById(id);
    return temp['name'];
  }

  ///PAGE
  setPageMedia(page: number) {
      // get pager object from service
      this.pager = this.pagerService.getPager(this.medias.length, page, this.pageSize);
      // console.log(this.pager);
      // this.currentPage = page;
      // get current page of items
     this.pagedItems = this.medias.slice(this.pager.startIndex, this.pager.endIndex + 1);
      // console.log(this.pagedItems);
  }

  setPage(page: number) {
      // get pagerProducts object from service
      this.pagerProducts = this.pagerService.getPager(this.products.length, page, this.pageSizeProducts);
      // console.log(this.pagerProducts);
     this.pagedItemsProducts = this.products.slice(this.pagerProducts.startIndex, this.pagerProducts.endIndex + 1);
      // console.log(this.pagedItemsProducts);
  }

}
