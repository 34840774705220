import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as $ from 'jquery';

import { MenuModel, SubmenuModel, MenuService, 
		 RoleModel, RoleService,
	     LibsService, PagerService, MysqlService, LocalData } from '../_services';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  menus = [];
  allSubmenus = [];
  submenus = [];
  submenusL2 = [];
  menusTotal = 0;

  roles = [];
  role: RoleModel = new RoleModel();

  title = 'Add';
  btnText = 'Submit';
  key = '';

  isModal = 'none';
  modalId = '';

  isSubmenuLoaded = false;
  isValidName = true;
  openAddRole = false;
  
  constructor(
  	private _libs: LibsService,
    private _localDB: LocalData,
    private _mysql: MysqlService,
  	private fb: FormBuilder,
    private menuService: MenuService,
    private roleService: RoleService,
  ) { 
  	this.menus = this._libs.getLocalStorageItem('menus');
    this.menusTotal = this.menus.length;
    this.allSubmenus = this._libs.getLocalStorageItem('allSubmenus');
    this.roles = this._libs.getLocalStorageItem('roles');
  }

  ngOnInit() {
    this._libs.loading();
    if (this.menus.length == 0){
      this.getMenusList();
    }
    if (this.allSubmenus.length == 0){
      this.getSubmenusList();
    }else{
      this.isSubmenuLoaded = true;
      this.submenus = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == false);
      this.submenusL2 = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == true);
      this._libs.unLoading();
    }
    if (this.roles.length == 0){
      this.getRolesList();
    }
  }

  getRolesList(){
  	this.roleService.getRolesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(roles => {
      this.roles = roles;
      // this._libs.setLocalStorageItem('roles', roles);
      this._libs.unLoading();

      // console.log(this.menus);
    });
  }

  getRole(id){
    this.key = id;
  	return (this.roles.filter(role => role['id'] == id))[0];
  }

  onAddSubmit(){
  	let name : string = (<HTMLInputElement>document.getElementById("name")).value; 
    
    if(name == '' || name == null){
      this.isValidName = false
      return;
    }else{
      this.isValidName = true;
    }

    this.role['permission'] = [];
    for (var i = 0; i < this.menusTotal; ++i) {
    	let permission = (<HTMLInputElement>document.getElementById("permission-"+i));
    	if (permission.checked == true){
    		this.role['permission'].push(permission.value);
    	}
    }
    
    // console.log(this.role);
    if (this.key){
      this.update(this.key, this._libs.convert2RoleModel(this.role));
    }else{
      this.save(this.role);
    }
  }

  save(f){
  	this.roleService.createRole(f);
  	this.reset();
  }

  update(k, f){
  	this.roleService.updateRole(k, f);
  	this.reset();
  }

  reset(){
    this.openAddRole = false;
    this.modalId = '';
    this.getRolesList();
    this.title = 'Add';
    this.btnText = 'Submit';
    this.key = '';
    this.role = new RoleModel();
    for (var i = 1; i < this.menusTotal; ++i) {
      (<HTMLInputElement>document.getElementById("permission-"+i)).checked = false
    }
  }

  isPermission(permissions,mId){
    // console.log(mId);
    // console.log(permissions);
    if (permissions != undefined){
      let idx = permissions.indexOf(mId);
      if (idx >= 0 ){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

  isPermissionCheck(id){
    // console.log(id);
    return this.isPermission(this.role['permission'], id);
  }

  addRole(){
    if (this.openAddRole){
      this.openAddRole = false;
    }else{
      this.openAddRole = true;
    }
  }

  roleEdit(id){
    this.openAddRole = true;
    this.btnText = 'Update';
    this.title = 'Edit';
    this.role = this.getRole(id);
  }

  roleDelete(id, modalId){
    this.modalId = modalId;
    this.role = this.getRole(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    this.roleService.deleteRole(this.key);
    this.closeModal();
  }

  closeModal(){
    this.isModal = this._libs.closeModal();
    this.reset();
  }

  ///MENU
  getMenusList(){
  	this.menuService.getMenusList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(menus => {
      this.menus = menus;
      this.menusTotal = this.menus.length;
      // this._libs.setLocalStorageItem('menus', menus);
      // console.log(this.menus);
    });
  }

  getSubmenusList(){
  	this.menuService.getSubmenusList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(allSubmenus => {
      this.allSubmenus = allSubmenus;
      this.submenus = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == false);
      this.submenusL2 = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == true);
      this._libs.setLocalStorageItem('allSubmenus', this.allSubmenus);

      this.isSubmenuLoaded = true;
      $.getScript('../../../assets/pages/scripts/ui-tree.min.js');
      // console.log(this.submenus);
    });
  }

}
