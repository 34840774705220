import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AlertService } from './alert.service';
import { LibsService } from './libs.service'
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authState: any = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private alert: AlertService,
    private afAuth: AngularFireAuth,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
    private _libs: LibsService,
  ) {
  }

  // doFacebookLogin(){
  //   return new Promise<any>((resolve, reject) => {
  //     let provider = new firebase.auth.FacebookAuthProvider();
  //     this.afAuth.auth
  //     .signInWithPopup(provider)
  //     .then(res => {
  //       resolve(res);
  //     }, err => {
  //       console.log(err);
  //       reject(err);
  //     })
  //   })
  // }

  // doTwitterLogin(){
  //   return new Promise<any>((resolve, reject) => {
  //     let provider = new firebase.auth.TwitterAuthProvider();
  //     this.afAuth.auth
  //     .signInWithPopup(provider)
  //     .then(res => {
  //       resolve(res);
  //     }, err => {
  //       console.log(err);
  //       reject(err);
  //     })
  //   })
  // }

  // doGoogleLogin(){
  //   return new Promise<any>((resolve, reject) => {
  //     let provider = new firebase.auth.GoogleAuthProvider();
  //     provider.addScope('profile');
  //     provider.addScope('email');
  //     this.afAuth.auth
  //     .signInWithPopup(provider)
  //     .then(res => {
  //       resolve(res);
  //     }, err => {
  //       console.log(err);
  //       reject(err);
  //     })
  //   })
  // }

  doDeleteUser(email, password){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then(function () {
         var user = firebase.auth().currentUser;
         user.delete();
      });
    })
    // var ref = firebase.database().ref(
    //   "users/".concat(id, "/")
    // );
    // ref.remove().then(function() {
    //   // return 'User Deleted';
    // }).catch(function(error) {
    //   // return error;

    // });
  }

  doRegister(f){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(f.email, f.password)
      .then(res => {
        resolve(res);
      }, err => reject(err))
    })
  }

  // Email/Password Login
  doEmailLogin(email, password){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
      .then(res => {
        resolve(res.user);
      }, err => reject(err))
    })
  }

  doSignOut() {
    this.afAuth.auth.signOut().then(() => {
      this._libs.go2Page('login');
    });
  }

  ///CHANGE PASSWORD
  reauthenticate = (currentPassword) => {
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(
        user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }

  doUpdatePassword = (currentPass, newpass) => {
    return new Promise<any>((resolve, reject) => {
      this.reauthenticate(currentPass).then(() => {
        var user = firebase.auth().currentUser;
        user.updatePassword(newpass).then(() => {
            let result = {'message': ''};
            result['message'] = "Password updated!";
            resolve(result);
          }, err => reject(err)
        )}, err => reject(err)
      );
    })

    // this.reauthenticate(currentPass).then(() => {
    //   var user = firebase.auth().currentUser;
    //   user.updatePassword(newpass).then(() => {
    //     console.log("Password updated!");
    //     return "Password updated!";
    //   }).catch((error) => { 
    //     console.log(error); 
    //     return error; 
    //   });
    // }).catch((error) => { 
    //   console.log(error); 
    //   return error; 
    // });
  }

   //// Email/Password Auth ////

  // emailSignUp(email: string, password: string) {
  //   return this.afAuth.auth
  //     .createUserWithEmailAndPassword(email, password)
  //     .then(credential => {
  //       this.alert.update('Welcome new user!', 'success');
  //       return this.updateUserData(credential.user); // if using firestore
  //     })
  //     .catch(error => this.handleError(error));
  // }

  // Sends email allowing user to reset password
  doResetPassword(email: string) {
    const fbAuth = firebase.auth();
    return fbAuth
      .sendPasswordResetEmail(email)
      .then(() => this.alert.update('Password update email sent', 'info'))
      .catch(error => this.handleError(error));
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    this.alert.update(error.message, 'error');
  }

  // // Sets user data to firestore after succesful login
  // private updateUserData(user: User) {
  //   const userRef: AngularFirestoreDocument<User> = this.afs.doc(
  //     `users/${user.uid}`
  //   );

  //   const data: User = {
  //     uid: user.uid,
  //     email: user.email || null,
  //     name: user.name || 'nameless user',
  //     photoURL: user.photoURL || 'https://goo.gl/Fz9nrQ'
  //   };
  //   return userRef.set(data);
  // }
  
}
