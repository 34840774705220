import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { MediaModel } from './media.model';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  mediaRef: AngularFireList<MediaModel> = null;

  constructor(
  	private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
  ) {
  	this.mediaRef = afData.list('/media');
  }

  getMediaList(): AngularFireList<MediaModel> {
    return this.mediaRef;
  }
  
  getMediaListBy(cId){
    return this.afData.list('/media/'+cId);
  }

  getMedia(cId, id): AngularFireList<MediaModel> {
    return this.afData.list('/media/'+cId+'/'+id);
  }

  updateMedia(cId, key, med) {
    this.mediaRef.set(key, med);
  }

  createMedia(cId, med: MediaModel): void {
    this.mediaRef = this.afData.list('/media/'+cId);
    this.mediaRef.push(med);
  }

  deleteMedia(cId, key: string): void {
    this.mediaRef = this.afData.list('/media/'+cId);
    this.mediaRef.remove(key).catch(error => this.handleError(error));
  }

  deleteAll(cId): void {
    this.mediaRef = this.afData.list('/media/'+cId);
    this.mediaRef.remove().catch(error => this.handleError(error));
  }
  
  private handleError(error) {
    console.log(error);
  }

}
