import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaymentModel, PaymentService, LibsService } from '../../_services';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  payments = [];
  payment: PaymentModel = new PaymentModel();
  form: FormGroup;
  isValidName = true;
  isValidImage = true;
  submitted = false;
  isModal = 'none';
  modalId = '';
  order = "name";
  ascending = false;
  key = '';
  btnText = 'Add';
  title = 'Add';

  constructor(
  	private paymentService: PaymentService,
  	private fb: FormBuilder,
    private _libs: LibsService,
  ) { 
    this.payments = this._libs.getLocalStorageItem('payments');
  }

  ngOnInit() {
  	this.createForm();
  	this.getPaymentsList();
  }

  getPaymentsList(){
  	this.paymentService.getPaymentsList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(payments => {
      this.payments = payments.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('payments', this.payments);
      console.log(this.payments);
    });
  }

  getPayment(id){
    this.key = id;
    let temp = this.payments.filter(payment => payment['id'] == id);
    return temp[0];
  }

  createForm() {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      image: new FormControl(),
    });
  }

  onSubmit() {
  	let name = this.form.controls['name'].value;
  	let image = this.form.controls['image'].value;
  	
  	if(name == '' || name == null){
  		this.isValidName = false
  		return;
  	}else{
  		this.isValidName = true;
  	}

  	if(image == '' || image == null){
  		this.isValidImage = false
  		return;
  	}else{
  		this.isValidImage = true;
  	}

    sessionStorage.removeItem('payments');
    this.payment['name'] = name; // = this.form.value;
    this.payment['image'] = image; // = this.form.value;
    // console.log(this.payment);
    if (this.key){      
      this.update(this.payment);
    }else{
      this.save(this.payment);
    }
  }

  update(f){
    this.paymentService.updatePayment(this.key, f);
    this.reset();
  }

  save(f) {
    this.paymentService.createPayment(f);
    this.reset();
  }

  reset(){
	this.title = 'Add';
	this.btnText = 'Add';
    this.payment = new PaymentModel();
    this.form.reset();
    this.getPaymentsList();
    this.key = '';
  }

  paymentEdit(id){
    this.btnText = 'Update';
    this.title = 'Edit';
    this.payment = this.getPayment(id);
  }

  paymentDelete(id, modalId){
    this.modalId = modalId;
    this.payment = this.getPayment(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    // console.log(this.payment['id']);
    this.paymentService.deletePayment(this.payment['id']);
    this.closeModal();
  }

  closeModal(){
    this.modalId = '';
    this.payment = new PaymentModel();
    this.isModal = this._libs.closeModal();
  }

}
