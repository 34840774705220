import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule  } from '@angular/http';
import { RouterModule, Routes} from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2PageScrollModule } from 'ng2-page-scroll';

import { environment } from '../environments/environment';

// AngularFire2 Modules`
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireFunctionsModule } from 'angularfire2/functions';

import { NgxQRCodeModule } from 'ngx-qrcode2';
import { NgxEditorModule } from 'ngx-editor';
import { NgSelectModule } from '@ng-select/ng-select';

import { AlertService, AuthenticationService, 
          LibsService, 
          LocalData, 
} from './_services';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';

import { LoginComponent } from './account/login/login.component';
import { ProfileComponent } from './account/profile/profile.component';
import { LogoutComponent } from './account/logout/logout.component';

import { DashboardComponent } from './dashboard/dashboard.component';

import { MenuComponent } from './menu/menu.component';
import { UsersComponent } from './users/users.component';
import { RolesComponent } from './roles/roles.component';
import { CompanyComponent } from './company/company.component';

import { ProductsComponent } from './products/products.component';
import { ServicesComponent } from './services/services.component';
import { CarriersComponent } from './carriers/carriers.component';

import { MediaComponent } from './media/media.component';
import { VideoComponent } from './media/video/video.component';
import { BrochureComponent } from './media/brochure/brochure.component';

import { CategoryComponent } from './general/category/category.component';

import { PricerangeComponent } from './filters/pricerange/pricerange.component';
import { TypeComponent } from './filters/type/type.component';
import { PaymentComponent } from './general/payment/payment.component';
import { ShippingRateComponent } from './general/shipping-rate/shipping-rate.component';

const appRoutes: Routes = [
  {
    path: '', 
    component: AppLayoutComponent,
    children: [
      { path: 'company', component: CompanyComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'users', component: UsersComponent },
      { path: 'roles', component: RolesComponent },
      { path: 'menu', component: MenuComponent },
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'media', component: MediaComponent },
      { path: 'video', component: VideoComponent },
      { path: 'brochure', component: BrochureComponent },
      { path: 'products', component: ProductsComponent },
      { path: 'carriers', component: CarriersComponent },
      { path: 'services', component: ServicesComponent },
      // // {path: 'development/:id', component: DevelopmentComponent},
      // { path: 'developments', component: ListdevelopmentsComponent },
      // { path: 'adddevelopment', component: AdddevelopmentComponent },
      // { path: 'editdevelopment/:id', component: AdddevelopmentComponent },
      // { path: 'developers', component: DeveloperComponent },
      // { path: 'siteplans', component: SiteplanslistComponent },
      // { path: 'addsiteplan', component: AddeditsiteplanComponent },
      // { path: 'editsiteplan/:id', component: AddeditsiteplanComponent },
      // { path: 'floorplans', component: FloorplanslistComponent },
      // { path: 'addfloorplan', component: AddeditfloorplanComponent },
      // { path: 'editfloorplan/:id', component: AddeditfloorplanComponent },
      // { path: 'packages', component: PackageslistComponent },
      // { path: 'addpackage', component: AddeditpackageComponent },
      // { path: 'editpackage/:id', component: AddeditpackageComponent },
      // { path: 'neighborhoods', component: ListneighborhoodsComponent },
      // { path: 'addneighborhood', component: AddneighborhoodComponent },
      // { path: 'editneighborhood/:id', component: AddneighborhoodComponent },
      { path: 'pricerange', component: PricerangeComponent },
      { path: 'type', component: TypeComponent },
      // { path: 'available', component: AvailablesComponent },
      // { path: 'features', component: FeaturesComponent },
      // { path: 'styles', component: StylesComponent },
      // { path: 'status', component: StatusComponent },
      // { path: 'propertytype', component: PropertytypeComponent },
      // { path: 'amenities', component: AmenitiesComponent },
      // { path: 'lotsize', component: LotsizeComponent },
      // { path: 'sqft', component: SqftComponent },
      // { path: 'timeonmarket', component: TimeonmarketComponent },
      // { path: 'yearbuilt', component: YearbuiltComponent },
      { path: 'category', component: CategoryComponent },
      { path: 'payment', component: PaymentComponent },
      { path: 'shippingrate', component: ShippingRateComponent },
      // { path: 'forms', component: FormsComponent },
      // { path: 'hoas', component: HoaComponent },
      // { path: 'lenders', component: LenderComponent },
      // { path: 'registers', component: RegisterComponent },
      // { path: 'openhouses', component: OpenhouseComponent },
      // { path: 'email', component: EmailComponent },
      // { path: 'property', component: PropertyComponent },
    ]
  },
  {
    path: '', 
    component: SiteLayoutComponent,
    children: [
          // { path: '', redirectTo: '/login', pathMatch: 'full'},
          { path: 'login', component: LoginComponent },
          { path: 'logout', component: LogoutComponent }
        ]
  },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AppLayoutComponent,
    SiteLayoutComponent,
    DashboardComponent,
    ProfileComponent,
    LogoutComponent,
    MenuComponent,
    UsersComponent,
    RolesComponent,
    CompanyComponent,
    MediaComponent,
    BrochureComponent,
    VideoComponent,
    CategoryComponent,
    ProductsComponent,
    ServicesComponent,
    CarriersComponent,
    PricerangeComponent,
    TypeComponent,
    PaymentComponent,
    ShippingRateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    Ng2PageScrollModule,
    HttpModule,
    HttpClientModule,
    RouterModule.forRoot (appRoutes, { useHash: true }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireDatabaseModule,
    NgxQRCodeModule,
    NgSelectModule,
    NgxEditorModule,
    
  ],
  providers: [
    LocalData
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
