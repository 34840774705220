export class VideoModel {
  name: string;
  url: string;
  thumb: string;
  type: string;

  constructor(){
    this.name = '';
    this.url = '';
    this.thumb = '';
    this.type = '';
  }
}
