import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CategoryModel, CategoryService, LibsService } from '../../_services';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  categories = [];
  category: CategoryModel = new CategoryModel();
  form: FormGroup;
  isValidName = true;
  submitted = false;
  isModal = 'none';
  modalId = '';
  order = "name";
  ascending = false;
  key = '';
  btnText = 'Add';
  title = 'Add';

  constructor(
  	private categoryService: CategoryService,
  	private fb: FormBuilder,
    private _libs: LibsService,
  ) { 
    this.categories = this._libs.getLocalStorageItem('categories');
  }

  ngOnInit() {
  	this.createForm();
  	this.getCategoriesList();
  }

  getCategoriesList(){
  	this.categoryService.getCategoriesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(categories => {
      this.categories = categories.filter(category => category['type'] == '1');
      this.categories = this.categories.sort(this._libs.dynamicSortByName('name'));
      // console.log(this.categories);
    });
  }

  getCategory(id){
    this.key = id;
    let temp = this.categories.filter(category => category['id'] == id);
    return temp[0];
    // for (var p = 0; p < this.categories.length; ++p){
    //   if (this.categories[p]['id'] == id){
    //     return this.categories[p];
    //   }
    // }
  }

  createForm() {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      type: new FormControl(),
    });
  }

  onSubmit() {
  	let name = this.form.controls['name'].value;
  	
  	if(name == '' || name == null){
  		this.isValidName = false
  		return;
  	}else{
  		this.isValidName = true;
  	}

    sessionStorage.removeItem('categories');
    this.category['name'] = name; // = this.form.value;
    this.category['type'] = '1';
    if (name.indexOf('@') >= 0){
      this.category['type'] = '2';
    }
    // console.log(this.category);
    if (this.key){      
      this.update(this.category);
    }else{
      this.save(this.category);
    }
  }

  update(f){
    this.categoryService.updateCategory(this.key, f);
    this.reset();
  }

  save(f) {
    this.categoryService.createCategory(f);
    this.reset();
  }

  reset(){
	  this.title = 'Add';
	  this.btnText = 'Add';
    this.category = new CategoryModel();
    this.form.reset();
    this.key = '';
  }

  categoryEdit(id){
    this.btnText = 'Update';
    this.title = 'Edit';
    this.category = this.getCategory(id);
  }

  categoryDelete(id, modalId){
    this.modalId = modalId;
    this.category = this.getCategory(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    // console.log(this.category['id']);
    this.categoryService.deleteCategory(this.category['id']);
    this.closeModal();
  }

  closeModal(){
    this.modalId = '';
    this.category = new CategoryModel();
    this.isModal = this._libs.closeModal();
  }

}
