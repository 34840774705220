import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { CarrierModel } from '../_services/carrier.model';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {
  carriersRef: AngularFireList<CarrierModel> = null;

  constructor(
  	private afData: AngularFireDatabase,
  ) {
  	this.carriersRef = afData.list('/carriers');
  }

  getCarriersList(): AngularFireList<CarrierModel> {
    return this.carriersRef;
  }

  getCarrier(id): AngularFireList<CarrierModel> {
    return this.afData.list('/carriers/'+id);
  }

  updateCarrier(key, dev) {
    this.carriersRef.set(key, dev);
  }

  createCarrier(carrier: CarrierModel): void {
    this.carriersRef.push(carrier);
  }

  deleteCarrier(key: string): void {
    this.carriersRef.remove(key).catch(error => this.handleError(error));
  }

  private handleError(error) {
    console.log(error);
  }
}
