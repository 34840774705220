import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { BrochureModel } from './brochure.model';

@Injectable({
  providedIn: 'root'
})
export class BrochureService {
  brochureRef: AngularFireList<BrochureModel> = null;

  constructor(
  	private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
  ) {
  	this.brochureRef = afData.list('/brochures');
  }

  getBrochuresList(): AngularFireList<BrochureModel> {
    return this.brochureRef;
  }

  getBrochure(id): AngularFireList<BrochureModel> {
    return this.afData.list('/brochures/'+id);
  }

  updateBrochure(key, values) {
    this.brochureRef.set(key, values);
  }

  createBrochure(values: BrochureModel): void {
    this.brochureRef.push(values);
  }

  deleteBrochure(key: string): void {
    this.brochureRef.remove(key).catch(error => this.handleError(error));
  }

  deleteAll(): void {
    this.brochureRef.remove().catch(error => this.handleError(error));
  }
  
  private handleError(error) {
    console.log(error);
  }
}
