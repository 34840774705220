import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { PriceRangeModel } from './pricerange.model';

@Injectable({
  providedIn: 'root'
})
export class PricerangeService {

  pricerangeRef: AngularFireList<PriceRangeModel> = null;

  constructor(
  	private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
  ) {
  	this.pricerangeRef = afData.list('/priceranges');
  }

  getPriceRangesList(): AngularFireList<PriceRangeModel> {
    return this.pricerangeRef;
  }

  getPriceRange(id): AngularFireList<PriceRangeModel> {
    return this.afData.list('/priceranges/'+id);
  }

  updatePriceRange(key, pricer) {
    this.pricerangeRef.set(key, pricer);
  }

  createPriceRange(pricer: PriceRangeModel): void {
    this.pricerangeRef.push(pricer);
  }

  deletePriceRange(key: string): void {
    this.pricerangeRef.remove(key).catch(error => this.handleError(error));
  }

  deleteAll(): void {
    this.pricerangeRef.remove().catch(error => this.handleError(error));
  }
  
  private handleError(error) {
    console.log(error);
  }
}
