import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PriceRangeModel, PricerangeService, LibsService } from '../../_services';

@Component({
  selector: 'app-pricerange',
  templateUrl: './pricerange.component.html',
  styleUrls: ['./pricerange.component.css']
})
export class PricerangeComponent implements OnInit {
  pricesDB = [];
  priceranges = [];
  pricerange: PriceRangeModel = new PriceRangeModel();
  form: FormGroup;
  isValidMin = true;
  isValidMax = true;
  isValidOrder = true;
  isModal = 'none';
  modalId = '';
  order = "name";
  ascending = false;
  priceKey = '';
  btnText = 'Submit';
  title = 'Add';

  constructor(
  	private pricerangeService: PricerangeService,
  	private fb: FormBuilder,
    private _libs: LibsService,
  ) { 
    // console.log(this.pricesDB);
  }

  ngOnInit() {
  	this.createForm();
  	this.getPriceRangesList();
  }

  getPriceRangesList(){
  	this.pricerangeService.getPriceRangesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(priceranges => {
      this.priceranges = priceranges.sort(this._libs.dynamicSortByName('order'));
      // console.log(this.priceranges);
    });
  }

  getPriceRange(id){
    this.priceKey = id;
    let price = this.priceranges.filter(price => price['id'] == id);
    return price[0];
  }

  createForm() {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      shortName: new FormControl(''),
    });
  }

  getName(){
    // console.log(e)
    let min : string = (<HTMLInputElement>document.getElementById("min")).value; 
    let max : string = (<HTMLInputElement>document.getElementById("max")).value; 
    
    if (!this.checkInput()){
      return;
    }
    
    if (max == '+'){
      this.pricerange['name'] = this._libs.numberWithCommas(min) +' +';
      this.pricerange['shortName'] = this._libs.convertShortPrice(min) +' +';
    }else{
      this.pricerange['name'] = this._libs.numberWithCommas(min) +' - '+ this._libs.numberWithCommas(max);
      this.pricerange['shortName'] = this._libs.convertShortPrice(min) +' - '+ this._libs.convertShortPrice(max);
    }
  }

  getMinName(){
    if (this.priceKey != ''){
      this.getName();
    }
  }

  checkInput(){
    let min : string = (<HTMLInputElement>document.getElementById("min")).value; 
    let max : string = (<HTMLInputElement>document.getElementById("max")).value; 
    let order : string = (<HTMLInputElement>document.getElementById("order")).value; 
    var res = true;

    if(min == '' || min == null){
      this.isValidMin = false;
      (<HTMLInputElement>document.getElementById("min")).focus();
      res = false;
    }else{
      this.isValidMin = true;
      if (max == '' || max == null){
          (<HTMLInputElement>document.getElementById("max")).focus();
          this.isValidMax = false;
          res = false;
      }else{
          this.isValidMax = true;
          res = true;
          if (order == ''){
            (<HTMLInputElement>document.getElementById("order")).focus();
            this.isValidOrder = false;
            res = false;
          }else{
            this.isValidOrder = true;
            res = true;
          }
      }
    }
    if (Number(max) < Number(min)){
      this.isValidMax = false;
      res = false;
    }

    return res;
  }

  onSubmit() {
    if (!this.checkInput()){
      return;
    }

    // this.pricerange['name'] = this._libs.numberWithCommas(name);
    // this.pricerange['shortName'] = 
    // console.log(this.pricerange);
    if (this.priceKey){
      this.update(this.pricerange);
    }else{
      this.save(this.pricerange);
    }
  }

  update(f){
    f = this._libs.convert2PriceModel(f);
    this.pricerangeService.updatePriceRange(this.priceKey, f);
    this.reset();
  }

  save(f) {
    this.pricerangeService.createPriceRange(f);
    this.reset();
  }

  reset(){
	this.title = 'Add';
	this.btnText = 'Submit';
    this.pricerange = new PriceRangeModel();
    this.form.reset();
    this.priceKey = '';
  }

  pricerangeEdit(id){
    this.btnText = 'Update';
    this.title = 'Edit';
    this.pricerange = this.getPriceRange(id);
  }

  pricerangeDelete(id, modalId){
    this.modalId = modalId;
    this.pricerange = this.getPriceRange(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    // console.log(this.pricerange['id']);
    this.pricerangeService.deletePriceRange(this.pricerange['id']);
    this.closeModal();
  }

  closeModal(){
    this.modalId = '';
    this.pricerange = new PriceRangeModel();
    this.isModal = this._libs.closeModal();
  }

  ///IMPORT DATA
  importPrices(){
    for (var i = 0; i < this.pricesDB.length; ++i) {
      console.log(this._libs.convert2PriceModel(this.pricesDB[i]));
      // this.save(this._libs.convert2PriceModel(this.pricesDB[i]));
    }
  }

}
