export class UserModel {
  email: string;
  firstname: string;
  lastname: string;
  provider: string;
  phone: string;
  ext: string;
  cellphone: string;
  occupation: string;
  about: string;
  avatar: string;
  roleId: string;
  password: string;
  active: boolean;
  emailPreference: number;

  constructor(){
    this.email = "";
    this.firstname = "";
    this.lastname = "";
    this.provider = "";
    this.phone = "";
    this.ext = "";
    this.cellphone = "";
    this.occupation = "";
    this.about = "";
    this.avatar = "";
    this.roleId = '';
    this.password = '';
    this.active = false;
    this.emailPreference = 3;
  }
}
