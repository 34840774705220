export class CompanyModel {
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  phone: string;
  phone2: string;
  fax: string;
  email: string;
  email2: string;
  website: string;
  facebook: string;
  instagram: string;
  twitter: string;
  skype: string;
  businessdays: string;
  businesshours: string;
  logo: string;
  logobw: string;
  logomob: string;
  images: any[];

  constructor(){
    this.name = "";
    this.address1 = "";
    this.address2 = "";
    this.city = "";
    this.state = "";
    this.zipcode = "";
    this.phone = "";
    this.phone2 = "";
    this.fax = "";
    this.email = "";
    this.email2 = "";
    this.website = "";
    this.facebook = "";
    this.instagram = "";
    this.twitter = "";
    this.skype = "";
    this.businessdays = "";
    this.businesshours = "";
    this.logo = "";
    this.logobw = "";
    this.logomob = "";
    this.images = [];
  }
}
