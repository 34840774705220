export class ServicesModel {
  name: string;
  code: string;
  price: string;

  constructor(){
    this.name = "";
    this.code = '';
    this.price = '';
  }
}
