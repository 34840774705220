import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { ProductModel } from '../_services/product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  productsRef: AngularFireList<ProductModel> = null;

  constructor(
  	private afData: AngularFireDatabase,
  ) {
  	this.productsRef = afData.list('/products');
  }

  getProductsList(): AngularFireList<ProductModel> {
    return this.productsRef;
  }

  getProduct(id): AngularFireList<ProductModel> {
    return this.afData.list('/products/'+id);
  }

  updateProduct(key, dev) {
    this.productsRef.set(key, dev);
  }

  createProduct(products: ProductModel): void {
    this.productsRef.push(products);
  }

  deleteProduct(key: string): void {
    this.productsRef.remove(key).catch(error => this.handleError(error));
  }

  private handleError(error) {
    console.log(error);
  }

}
